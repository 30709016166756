import { gql } from "@apollo/client";

export let RESOURCE_LITERATURE_DATA = gql`
query ($documentTypes: [DocumentInputType]!, $ticker: String!) {
  resources {
    completeFundData(ticker: $ticker, documentTypes: $documentTypes) {
	type
	funds {
	  id
	  friendlyName
	  fileName
	  createdAt
	  documentType
	  locationId
	  ticker
	}
    }
  }
}
`;

export let NAV_DAILY_DATA = gql`
query fund ($ticker: String!) {
    fund(ticker: $ticker) {
        dailyData {
            asOfDate
            ticker
            CUSIP
            inceptionDate
            NAV
            NAVChange
            marketPrice
            distributionRate
            premiumDiscount
            navTicker
            monthlyDistributionRate
            distributionRate
            totalManagedAssets
            totalNetAssets
            shareOutstanding
            preferredShare
            preferredDate
            leverageRatio
            thirtyDayMedianSpread
        }
        fullHoldings {
            asOfDate
            description
            ticker
            totalNetAssetPercent
            type
            marketValue
            percentMarketValue
        }
        distribution {
            exDate
            recordDate
            payableDate
            amount
            distributionType
            declarationDate
            sec19ANotice {
                id
                friendlyName
                fileName
                createdAt
                documentType
                locationId
                ticker
            }
        }
        quarterlyPerformance {
            asOfDate
            oneYearMarket
            threeYearMarket
            fiveYearMarket
            tenYearMarket
            fifteenYearMarket
            s1Market
            oneYearNav
            threeYearNav
            fiveYearNav
            tenYearNav
            fifteenYearNav
            twentyYearNav
            twentyYearMarket
            s1Nav
        }
        indexQuarterlyPerformance {
            asOfDate
            oneYear
            fiveYear
            tenYear
            sinceInceptionAnnualized
        }
        monthlyPerformance {
            asOfDate
            oneMonthMarket
            threeMonthMarket
            ytdMarket
            siMarket
            oneMonthNav
            threeMonthNav
            ytdNav
            siNav
            siAnnualMarket
            siAnnualNav
            oneYearMarket
            oneYearNav
            threeYearMarket
            threeYearNav
            fiveYearMarket
            fiveYearNav
            tenYearMarket
            tenYearNav
            fifteenYearMarket
            fifteenYearNav
            sixMonthNav
            sixMonthMarket
        }
        indexMonthlyPerformance {
            id
            ticker
            asOfDate
            oneMonth
            threeMonth
            ytd
            sinceInceptionAnnualized
            sixMonth
            oneYear
            threeYear
            fiveYear
            tenYear
            fifteenYear
            sinceInceptionCumulative
            indexName
            order
        }
        indexQuarterlyPerformance {
            id
            ticker
            asOfDate
            oneMonth
            threeMonth
            ytd
            sinceInceptionAnnualized
            sixMonth
            oneYear
            threeYear
            fiveYear
            tenYear
            fifteenYear
            twentyYear
            sinceInceptionCumulative
            indexName
            order
        }
        fundCharacteristics {
            id
            ticker
            asOfDate
            noOfHoldings
            effecMaturity
            bondPricePercentage
            thirtyDaySecYield
            distributionYield
        }
        assetAllocation {
            id
            ticker
            asOfDate
            assetName
            assetPercentage
        }
    }
}
`;

export let SECTOR_DATA = gql`
query fund ($ticker: String!) {
    fund(ticker: $ticker) {
        industryAllocation{
            n
            v
        }
    }
}
`;

export let STEW_INDUSTRY_DATA = gql`
query fund ($ticker: String!) {
    fund(ticker: $ticker) {
        industryAllocationData{
            id
            ticker
            asOfDate
            name
            weight
        }
    }
}
`;

// sectorAllocation {
//     n
//     v
// }

export let HOLDINGS_DATA = gql`
query fund ($ticker: String!, $startDate: Date, $endDate: Date) {
    fund(ticker: $ticker) {
        dataLakeDailyFullHoldings(startDate: $startDate, endDate: $endDate) {
            id
            name
            hTicker
            secNumFull
            sharesPar
            holdingDate
            marketValue
            weight
        }
    }
}
`;
export let PREMIUM_DATA = gql`
query fund ($ticker: String!, $startDate: Date, $endDate: Date) {
    fund(ticker: $ticker) {
        premiumDiscountCount(startDate: $startDate, endDate: $endDate)
        premiumDiscountChart(startDate: $startDate, endDate: $endDate){
            premium
            period
        }
    }
}
`;

export let CONTACT_US = gql`
mutation sendContactUsMail ($emailBody: String, $emailSubject: String, $emails: [String], $ticker: String) {
    sendContactUsMail(emailBody: $emailBody, emailSubject: $emailSubject, emails: $emails, ticker: $ticker) {
        ok
    }
}
`;
