import React, { useState, useEffect } from "react";
import Fundheader from "../../../components/fundheader";
import Footer from "../../../components/STEW/footer";
import NAV from "../../../components/nav";
import { NAV_DAILY_DATA } from "../../../utils/queries";
import { useQuery } from "@apollo/client";

function Disclosure() {
    const [modalShow, setModalShow] = useState(false);
    const [chartShow, setChartShow] = useState(false);
    const [dailyData, setDailyData] = useState(null);

    const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "STEW" }, });

    useEffect(() => {
        document.title = "SRH Total Return Fund - Disclosure";
    }, []);

    useEffect(() => {
        setDailyData(navData?.fund?.dailyData)
    }, [navData])

    return (
        <>
            <Fundheader fund="STEW"></Fundheader>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short">
                        <NAV dailyData={dailyData} bgImg={"bgImage__img-10"} title={'Disclosure'} ticker={"STEW"} />
                    </div>
                    <div class="container inner-content clearfix">
                        <div class="row">

                            <div class="col span_12_of_12">

                                <section>
                                    <spacer></spacer>
                                    <p>Not FDIC Insured | May Lose Value | No Bank Guarantee</p>
                                    <p> SRH Total Return Fund, Inc. is a closed-end investment company. To read more
                                        about the Fund, please access the Annual and Semi-Annual Reports in the literature
                                        section of this website
                                        to receive a copy of the Annual
                                        and Semi-Annual Reports by mail. Investors should consider investment objectives,
                                        risks, charges and expenses carefully before investing.</p>
                                    <p>The performance data quoted for the Fund is provided by Paralel Technologies LLC and
                                        represents past performance. This information is unaudited and neither the Fund's
                                        advisers, their affiliates, the Fund, nor any other person guarantees its accuracy.
                                        Past performance is no guarantee of future results. The investment return and
                                        principal value of an investment will fluctuate and shares, when sold, may be worth
                                        more or less than their original cost. The information provided on this website
                                        should not be construed as research, trading tips or recommendations, or investment
                                        advice, and is provided with no warrants as to its accuracy.</p>
                                </section>
                                <spacer></spacer>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Footer setModalShow={setModalShow}></Footer>
        </>
    );
}

export default Disclosure;
