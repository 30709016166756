function Advisor(props) {
    const { dailyData, fundData } = props
    return (
        <>
            <div className="section__bg-2 hero_waypoint">
                <div className="container clearfix inner-content">
                    <div className="col span_1_of_1">
                        <h3 className="underline">Advisor</h3>
                    </div>
                </div>
            </div>
            <div id="advisor" className="waypoint__subnav"></div>

            <div className="container">
                <div className="row">
                    <div>
                        <p>Paralel Advisors serves as the investment adviser to the Fund. The strategy is managed by Rocky Mountain Advisers (RMA), a sub-adviser to the Fund. Vident Advisory (Vident) serves as the trading sub-adviser to the Fund.</p>
                    </div>
                </div>
                <div className="row">
                    <div>
                        <h5 className="underline">
                            Portfolio Managers
                        </h5>
                        <div class="container bioSection">
                            <div class="col span_1_of_2">
                                <h5 class="underline">Joel Looney, CFP</h5>
                                <p></p>
                                <p>Joel Looney, CFP, serves as President, Chief Investment Officer and Portfolio Manager for RMA. Mr. Looney has over 25 years of portfolio management experience and is responsible for supervising the overall advisory services provided by RMA and management of the firm’s investment strategies. </p>
                                <p>Mr. Looney holds an M.B.A from Kansas State University and a B.S. from Marymount College and is a CFP professional.</p>
                                <p></p>
                            </div>
                            <div class="col span_1_of_2">
                                <h5 class="underline">Jacob Hemmer, CFA</h5>
                                <p></p>
                                <p>Jacob Hemmer, CFA, serves as Vice President and Portfolio Manager for RMA. He has over 10 years of investment management experience and is responsible for the oversight, investment research, and management of the firm’s investment strategies. 
                                </p>
                                <p>Mr. Hemmer holds a B.S. in Business from Colorado State University and holds the Chartered Financial Analyst designation.
                                </p>
                                <p></p>
                            </div>
                        </div>
                        <div class="container bioSection">
                            <div class="col span_1_of_2">
                                <h5 class="underline">Rafael Zayas, CFA</h5>
                                <p></p>
                                <p>Rafael Zayas, CFA is a Senior Vice President and Head of Portfolio Management & Trading at Vident. Mr. Zayas has over 15 years of trading and portfolio management experience in global equity products and ETFs. Mr. Zayas specializes in managing and trading of developed, emerging, and frontier market portfolios. </p>
                                <p>Mr. Zayas holds a BS in Electrical Engineering from Cornell University. He also holds the Chartered Financial Analyst designation.</p>
                                <p></p>
                            </div>
                            <div class="col span_1_of_2">
                                <h5 class="underline">Austin Wen, CFA</h5>
                                <p></p>
                                <p>Austin Wen is a Portfolio Manager at Vident. He has more than eight years of investment management experience. Mr. Wen specializes in portfolio management and trading of equity portfolios and commodities-based portfolios, as well as risk monitoring and investment analysis.  
                                </p>
                                <p>Mr. Wen obtained a BA in Finance from the University of Georgia and holds the Chartered Financial Analyst designation.
                                </p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </>
    );
}

export default Advisor;
