import React, { useState, useEffect } from "react";
import Fundheader from "../../../components/fundheader";
import Footer from "../../../components/STEW/footer";
import NAV from "../../../components/nav";
import { NAV_DAILY_DATA } from "../../../utils/queries";
import { useQuery } from "@apollo/client";

function PrivacyPolicy() {
    const [modalShow, setModalShow] = useState(false);
    const [chartShow, setChartShow] = useState(false);
    const [dailyData, setDailyData] = useState(null);

    const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "STEW" }, });

    useEffect(() => {
        document.title = "SRH Total Return Fund - Privacy Policy";
    }, []);

    useEffect(() => {
        setDailyData(navData?.fund?.dailyData)
    }, [navData])

    return (
        <>
            <Fundheader fund="STEW"></Fundheader>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short">
                        <NAV dailyData={dailyData} bgImg={"bgImage__img-10"} title={'Privacy Policy'} ticker={"STEW"} />
                    </div>
                    <div className="container inner-content clearfix">
                        <div className="row">

                            <div className="col span_12_of_12">
                                <spacer></spacer>
                                <section>
                                    <h4>LAST UPDATED: August 31, 2021</h4>
                                    <spacer></spacer>
                                    <h3 className="nomargin underline">PRIVACY POLICY</h3>


                                    <p>This Privacy Policy describes our practices in connection with information that we
                                        collect through websites operated by Paralel Technologies LLC and its subsidiaries (“us”
                                        or “Paralel”) from which you are accessing this Privacy Policy (the “Websites”),
                                        through the software applications made available by us for use on or through
                                        computers and mobile devices (the “Apps”), through our social media pages
                                        (collectively, our “Social Media Pages”) (collectively, including the Websites, the
                                        Apps and our Social Media Pages, the “Services”). By using the Services, you agree
                                        to this Privacy Policy. The Privacy Policy also describes our practices in
                                        connection with information that we collect in person, over the phone, via email and
                                        from affiliates and third parties. Fund investors should refer to a fund’s privacy
                                        policy.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="underline">PERSONAL INFORMATION</h3>


                                    <p>“Personal Information” is information that identifies you as an individual or relates
                                        to an identifiable individual, including but not limited to:</p>

                                    <ul>
                                        <li className="indent li-disc">Name</li>
                                        <li className="indent li-disc">Postal address (including billing and shipping addresses)
                                        </li>
                                        <li className="indent li-disc">Telephone number</li>
                                        <li className="indent li-disc">Email address</li>
                                        <li className="indent li-disc">Profile picture</li>
                                        <li className="indent li-disc">Social media account ID</li>
                                    </ul>
                                    <p>We may need to collect and process these types of Personal Information in order to
                                        provide the requested Services to you, or because we are legally required to do so.
                                        If you do not provide the information that we request, we may not be able to provide
                                        you with the requested Services.</p>

                                    <p><b>Collection of Personal Information</b></p>

                                    <p>We and our service providers may collect Personal Information in a variety of ways,
                                        including:</p>

                                    <ul>
                                        <li className="indent li-disc"><b>Through the Services:</b> We may collect Personal
                                            Information through the Services.</li>
                                        <li className="indent li-disc"><b>Offline:</b> We may collect Personal Information from
                                            you offline, such as when you attend one of our trade shows.</li>
                                        <li className="indent li-disc"><b>From Other Sources:</b> We may receive your Personal
                                            Information from other sources, such as public databases, joint marketing
                                            partners, social media platforms (including from people with whom you are
                                            friends or otherwise connected) and from affiliates and third parties. In
                                            addition, if you elect to connect your social media account to your Website
                                            account, certain Personal Information from your social media account will be
                                            shared with us, which may include Personal Information that is part of your
                                            profile or your friends’ profiles.</li>
                                    </ul>

                                    <p>If you disclose any Personal Information relating to other people to us or to our
                                        service providers in connection with the Services, you represent that you have the
                                        authority to do so and to permit us to use the information in accordance with this
                                        Privacy Policy.</p>


                                    <p><b>Use of Personal Information</b></p>

                                    <p>We and our service providers may use Personal Information for our legitimate business
                                        interests, including the following:</p>

                                    <ul>
                                        <li className="indent li-disc">To respond to your inquiries and fulfill your requests,
                                            complete your transactions, and provide you with related customer service.</li>
                                        <li className="indent li-disc">To send administrative information to you, such as
                                            changes to our terms, conditions and policies, as well as marketing
                                            communications that we believe may be of interest.</li>
                                        <li className="indent li-disc">To personalize your experience on the Services by
                                            presenting products and offers tailored to you, and to facilitate social sharing
                                            functionality.</li>
                                        <li className="indent li-disc">For our business purposes, such as data analysis, audits,
                                            fraud monitoring and prevention, developing new products, enhancing, improving
                                            or modifying our Services, identifying usage trends, determining the
                                            effectiveness of our promotional campaigns, and operating and expanding our
                                            business activities.</li>
                                    </ul>

                                    <br />
                                    <p><b>Disclosure of Personal Information</b></p>

                                    <p>Your Personal Information may be disclosed to our affiliates and is subject to the
                                        following policy: <a href="https://www.ssctech.com/about-us/privacy" target="_blank">https://www.ssctech.com/about-us/privacy</a>. Additionally,
                                        personal information may be shared by us to:</p>

                                    <ul>
                                        <li className="indent li-disc">Third party service providers who provide services such
                                            as website hosting, data analysis, information technology and related
                                            infrastructure provision, customer service, email delivery, auditing and other
                                            services.</li>
                                        <li className="indent li-disc">Third parties, to permit them to send you marketing
                                            communications, consistent with your choices.</li>
                                        <li className="indent li-disc">By you, on message boards, chat, profile pages and blogs
                                            and other services to which you are able to post information and content
                                            (including, without limitation, our Social Media Pages). Please note that any
                                            information you post or disclose through these services will become public and
                                            may be available to other users and the general public.</li>
                                        <li className="indent li-disc">To your friends associated with your social media
                                            account, to other website users and to your social media account provider, in
                                            connection with your social sharing activity. By connecting your Services
                                            account and your social media account, you authorize us to share information
                                            with your social media account provider, and you understand that the use of the
                                            information we share will be governed by the social media provider’s privacy
                                            policy.</li>
                                    </ul>
                                    <br />
                                    <p><b>Other Uses and Disclosures</b></p>

                                    <p>We may also use and disclose your Personal Information as we believe to be necessary
                                        or appropriate: (a) to comply with applicable law, which may include laws outside
                                        your country of residence, to respond to requests from public and government
                                        authorities, which may include authorities outside your country of residence, to
                                        cooperate with law enforcement, or for other legal reasons; (b) to enforce our terms
                                        and conditions; and (c) to protect our rights, privacy, safety or property, and/or
                                        that of our affiliates, you or others.</p>

                                    <p>In addition, we may use, disclose or transfer your information to a third party in
                                        the event of any reorganization, merger, sale, joint venture, assignment, transfer
                                        or other disposition of all or any portion of our business, assets or stock
                                        (including in connection with any bankruptcy or similar proceedings).</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">OTHER INFORMATION</h3>

                                    <p>“Other Information” is any information that does not reveal your specific identity or
                                        does not directly relate to an identifiable individual, such as:</p>

                                    <ul>
                                        <li className="indent li-disc">Browser and device information</li>
                                        <li className="indent li-disc">App usage data</li>
                                        <li className="indent li-disc">Information collected through cookies, pixel tags and
                                            other technologies</li>
                                        <li className="indent li-disc">Demographic information and other information provided by
                                            you that does not reveal your specific identity</li>
                                        <li className="indent li-disc">Information that has been aggregated in a manner that it
                                            no longer reveals your specific identity</li>
                                    </ul>

                                    <p>If we are required to treat Other Information as Personal Information under
                                        applicable law, then we may use and disclose it for the purposes for which we use
                                        and disclose Personal Information as detailed in this Policy.</p>

                                    <p><b>Collection of Other Information</b></p>

                                    <ul>
                                        <li className="indent li-disc"><b>Through your browser or device:</b> Certain
                                            information is collected by most browsers or automatically through your device,
                                            such as your Media Access Control (MAC) address, computer type (Windows or Mac),
                                            screen resolution, operating system name and version, device manufacturer and
                                            model, language, Internet browser type and version and the name and version of
                                            the Services (such as the App) you are using. We use this information to ensure
                                            that the Services function properly.</li>
                                        <li className="indent li-disc"><b>Through your use of the App:</b> When you download and
                                            use the App, we and our service providers may track and collect App usage data,
                                            such as the date and time the App on your device accesses our servers and what
                                            information and files have been downloaded to the App based on your device
                                            number.</li>
                                        <li className="indent li-disc"><b>Using cookies:</b> Cookies are pieces of information
                                            stored directly on the computer that you are using. Cookies allow us to collect
                                            information such as browser type, time spent on the Services, pages visited,
                                            language preferences, and other anonymous traffic data. We and our service
                                            providers use the information for security purposes, to facilitate navigation,
                                            to display information more effectively, and to personalize your experience. We
                                            also gather statistical information about use of the Services in order to
                                            continually improve their design and functionality, understand how they are used
                                            and assist us with resolving questions regarding them. We do not currently
                                            respond to browser do-not-track signals. If you do not want information
                                            collected through the use of cookies, most browsers allow you to automatically
                                            decline cookies or be given the choice of declining or accepting a particular
                                            cookie (or cookies) from a particular website. You may also wish to refer to <a href="http://www.allaboutcookies.org/manage-cookies/index.html" target="_blank">http://www.allaboutcookies.org/manage-cookies/index.html</a>
                                            for general information about cookies, and to your browser’s help files for
                                            browser-specific directions related to cookies. If, however, you do not accept
                                            cookies, you may experience some inconvenience in your use of the Services. You
                                            also may not receive advertising or other offers from us that are relevant to
                                            your interests and needs.</li>
                                        <li className="indent li-disc"><b>Using pixel tags and other similar technologies:</b>
                                            Pixel tags (also known as web beacons and clear GIFs) may be used to, among
                                            other things, track the actions of users of the Services (including email
                                            recipients), measure the success of our marketing campaigns and compile
                                            statistics about usage of the Services and response rates.</li>
                                        <li className="indent li-disc"><b>Analytics:</b> We use Adobe Analytics, which uses
                                            cookies and similar technologies to collect and analyze information about use of
                                            the Services and report on activities and trends. This service may also collect
                                            information regarding the use of other websites, apps and online resources. You
                                            can learn about Adobe’s practices by going to <a href="http://www.adobe.com/privacy/policy.html" target="_blank">http://www.adobe.com/privacy/policy.html</a>.</li>
                                        <li className="indent li-disc"><b>IP Address:</b> Your IP address is automatically
                                            assigned to your computer by your Internet Service Provider. An IP address may
                                            be identified and logged automatically in our server log files whenever a user
                                            accesses the Services, along with the time of the visit and the page(s) that
                                            were visited. Collecting IP addresses is standard practice and is done
                                            automatically by many websites, applications and other services. We use IP
                                            addresses for purposes such as calculating usage levels, diagnosing server
                                            problems and administering the Services. We may also derive your approximate
                                            location from your IP address.</li>
                                    </ul>

                                    <p><b>Uses and Disclosures of Other Information</b></p>

                                    <p>We may use and disclose Other Information for any purpose, except where we are
                                        required to do otherwise under applicable law. In some instances, we may combine
                                        Other Information with Personal Information. If we do, we will treat the combined
                                        information as Personal Information as long as it is combined.</p>
                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">THIRD PARTY SERVICES</h3>

                                    <p>This Privacy Policy does not address, and we are not responsible for, the privacy,
                                        information or other practices of any third parties, including any third party
                                        operating any website or service to which the Services link. The inclusion of a link
                                        on the Services does not imply endorsement of the linked site or service by us or by
                                        our affiliates.</p>

                                    <p>We are not responsible for the information collection, use, disclosure or security
                                        policies or practices of other organizations, such as LinkedIn or any other app
                                        developer, app provider, social media platform provider, operating system provider,
                                        wireless service provider or device manufacturer, including with respect to any
                                        Personal Information you disclose to other organizations through or in connection
                                        with the Apps or our Social Media Pages.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">SECURITY</h3>

                                    <p>We seek to use reasonable organizational, technical and administrative measures to
                                        protect Personal Information within our organization. Unfortunately, no data
                                        transmission or storage system can be guaranteed to be 100% secure. If you have
                                        reason to believe that your interaction with us is no longer secure, please
                                        immediately notify us in accordance with the “Contacting Us” section below.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">CHOICES AND ACCESS</h3>

                                    <p><b>Your choices regarding our use and disclosure of your Personal Information</b></p>

                                    <p>We give you choices regarding our use and disclosure of your Personal Information for
                                        marketing purposes. You may opt-out from:</p>

                                    <ul>
                                        <li className="indent li-disc"><b>Receiving electronic communications from us:</b> If
                                            you no longer want to receive marketing-related emails from us on a
                                            going-forward basis, you may opt-out by clicking the applicable link provided
                                            within such marketing-related email and following the instructions for updating
                                            your preferences as provided therein.</li>
                                        <li className="indent li-disc"><b>Our sharing of your Personal Information with
                                            affiliates for their direct marketing purposes:</b> If you would prefer that
                                            we not share your Personal Information on a going-forward basis with our
                                            affiliates for their direct marketing purposes, you may opt-out of this sharing
                                            by clicking the applicable link provided within such marketing-related email and
                                            following the instructions for updating your preferences as provided therein.
                                        </li>
                                    </ul>

                                    <p>We will try to comply with your request(s) as soon as reasonably practicable. Please
                                        note that if you opt-out of receiving marketing-related emails from us, we may still
                                        send you important administrative messages, from which you cannot opt-out.</p>

                                    <p><b>How you can access, change or suppress your Personal Information</b></p>

                                    <p>If you would like to review, correct, update, suppress, restrict or delete Personal
                                        Information that you have previously provided to us, you may do so by using the
                                        self-service functionality within the applicable Service.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">RETENTION PERIOD</h3>

                                    <p>We will retain your Personal Information for as long as needed or permitted in light
                                        of the purpose(s) for which it was obtained. The criteria used to determine our
                                        retention periods include: (i) the length of time we have an ongoing relationship
                                        with you and provide the Services to you; (ii) whether there is a legal obligation
                                        to which we are subject; or (iii) whether retention is advisable in light of our
                                        legal position (such as in regard to applicable statutes of limitations, litigation
                                        or regulatory investigations).</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">USE OF SERVICES BY MINORS</h3>

                                    <p>The Services are not directed to individuals under the age of eighteen (18), and we
                                        do not knowingly collect Personal Information from individuals under 18.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">JURISDICTION AND CROSS-BORDER TRANSFER</h3>

                                    <p>The Services are controlled and operated by us from the United States and are not
                                        intended to subject us to the laws or jurisdiction of any state, country or
                                        territory other than that of the United States. Your Personal Information may be
                                        stored and processed in any country where we have facilities or in which we engage
                                        service providers, and by using the Services you consent to the transfer of
                                        information to countries outside of your country of residence, including the United
                                        States, which may have data protection rules that are different from those of your
                                        country. In certain circumstances, courts, law enforcement agencies, regulatory
                                        agencies or security authorities in those other countries may be entitled to access
                                        your Personal Information.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">SENSITIVE INFORMATION</h3>

                                    <p>We ask that you not send us, and you not disclose, any sensitive Personal Information
                                        (e.g., social security numbers, information related to racial or ethnic origin,
                                        political opinions, religion or other beliefs, health, biometrics or genetic
                                        characteristics, or criminal background) on or through the Services or otherwise to
                                        us.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">UPDATES TO THIS PRIVACY POLICY</h3>

                                    <p>We may change this Privacy Policy. The “LAST UPDATED” legend at the top of this
                                        Privacy Policy indicates when this Privacy Policy was last revised. Any changes will
                                        become effective when we post the revised Privacy Policy on the Services. Your use
                                        of the Services following these changes means that you accept the revised Privacy
                                        Policy.</p>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">IMPORTANT INFORMATION FOR CALIFORNIA RESIDENTS</h3>

                                    <p>This California Consumer Privacy Act Notice provides information for certain
                                        California residents in connection with the California Consumer Privacy Act of 2018
                                        (“CCPA”). Under the CCPA, and for purposes of this California Consumer Privacy Act
                                        Notice, “Personal Information” generally means information that identifies, relates
                                        to, or describes a California resident or household and includes the categories
                                        listed below. Below are details about the categories of Personal Information of
                                        California residents that we have collected or disclosed during the last 12 months.
                                    </p>

                                    <p><strong>Categories of Personal Information Collected:</strong> We collected the
                                        following categories of Personal Information:</p>

                                    <ul>
                                        <li className="indent li-latin">Identifiers, such as name, contact information, and
                                            online identifiers;</li>
                                        <li className="indent li-latin">Personal information, as defined in the California
                                            customer records law, such as employment and government-issued ID numbers;</li>
                                        <li className="indent li-latin">Internet or network activity information, such as
                                            browsing history and interactions with our website;</li>
                                        <li className="indent li-latin">Professional or employment-related information, such as
                                            company name; and</li>
                                        <li className="indent li-latin">Inferences drawn from any of the Personal Information
                                            listed above to create a profile or summary about, for example, an individual’s
                                            preferences and characteristics.</li>
                                    </ul>

                                    <p><strong>Sources of Personal Information.</strong> We collected Personal Information
                                        directly from California residents themselves in person, over the phone, via email,
                                        via the Services and separately from affiliates and third parties.</p>

                                    <p><strong>Purposes.</strong> We may use this Personal Information to respond to your
                                        requests, provide our products and services, and to accomplish our business purposes
                                        and objectives, including, for example, to market our products and services and
                                        those of our clients, conduct analytics, comply with law, legal process, and
                                        internal policies, and maintain records.</p>

                                    <p><strong>Disclosures of Personal Information.</strong> We disclosed the following
                                        Personal Information to our affiliates and service providers for our operational
                                        business purposes:</p>

                                    <ul>
                                        <li className="indent li-latin">Identifiers, such as name, contact information, and
                                            online identifiers;</li>
                                        <li className="indent li-latin">Personal information, as defined in the California
                                            customer records law, such as employment and government-issued ID numbers;</li>
                                        <li className="indent li-latin">Internet or network activity information, such as
                                            browsing history and interactions with our website;</li>
                                        <li className="indent li-latin">Professional or employment-related information, such as
                                            company name; and</li>
                                        <li className="indent li-latin">Inferences drawn from any of the Personal Information
                                            listed above to create a profile or summary about, for example, an individual’s
                                            preferences and characteristics.</li>
                                    </ul>


                                    <p><strong>“Sales” of Personal Information.</strong> The CCPA defines a “sale” as the
                                        disclosure of Personal Information in exchange for monetary or other valuable
                                        consideration. We have not “sold” Personal Information for purposes of the CCPA. The
                                        CCPA definition of “sale” does not include, for example, the transfer of Personal
                                        Information as an asset that is part of a merger, bankruptcy, or other disposition
                                        of all or any portion of our business.</p>

                                    <p>If you are a California resident, you have the following rights:</p>

                                    <ul>
                                        <li className="indent"><strong>(1) Right to Know.</strong> You have the right to request
                                            that we disclose to you the following information covering the 12 months
                                            preceding your request:
                                        </li><li className="li-circle">The categories of Personal Information we collected about you
                                            and the categories of sources from which we collected such Personal Information;
                                        </li>
                                        <li className="li-circle">The specific pieces of Personal Information we collected about
                                            you; </li>
                                        <li className="li-circle">The business or commercial purpose for collecting Personal
                                            Information about you; and </li>
                                        <li className="li-circle">The categories of Personal Information about you that we
                                            otherwise shared or disclosed, and the categories of third parties with whom we
                                            shared or to whom we disclosed such Personal Information. </li>

                                        <li className="indent"><strong>(2) Right to Request Deletion.</strong> You have the
                                            right to request that we delete Personal Information we collected from you.
                                            <br />
                                        </li><li className="indent-extra">To make a request for the disclosures or deletion described
                                            above, please contact us at <a href="mailto:bifclientinquiries@paralel.com">bifclientinquiries@paralel.com</a> or call us
                                            at (844) 511-9635. We will respond to your request consistent with applicable
                                            law.</li>
                                        <li className="indent-extra">In order to verify your identity, Paralel will provide
                                            you with a CCPA request document, which will include a request for information
                                            including your name, your home and work addresses, your home and work email
                                            addresses and your home (or cell) and work phone numbers. We will also ask you
                                            to provide information about your relationship with Paralel.</li>
                                        <li className="indent-extra">If you are requesting disclosure of specific pieces of
                                            information maintained by Paralel, we will ask you to provide a signed
                                            declaration under penalty of perjury that you are the “Consumer” whose personal
                                            information is the subject of the request.</li>
                                        <li className="indent-extra">If you are an “Authorized Agent” making a request on behalf
                                            of a Consumer, in addition to the above information regarding the Consumer, we
                                            will ask you to provide a copy of a document permitting you to act on behalf of
                                            the Consumer, signed by you and the Consumer. Paralel will also require that
                                            the Consumer confirm directly to Paralel that they provided you permission to
                                            submit the request.</li>

                                        <li className="indent"><strong>(3) Right to Be Free from Discrimination.</strong> You
                                            have the right to be free from unlawful discrimination for exercising your
                                            rights under the CCPA.</li>
                                    </ul>

                                </section>

                                <spacer></spacer>

                                <section>

                                    <h3 className="nomargin underline">CONTACTING US</h3>

                                    <p>Paralel Advisors LLC., located at
                                        1700 Broadway, Suite 1850, Denver, CO 80290, is the company responsible for the
                                        collection, use and disclosure of your Personal Information under this Privacy
                                        Policy. If you have any questions about this Privacy Policy, please contact us at <a href="mailto:bifclientinquiries@paralel.com">bifclientinquiries@paralel.com</a>. Because email
                                        communications are not always secure, please do not include sensitive information in
                                        your emails to us.</p>

                                </section>

                                <spacer></spacer>



                                <div id="contentEndTrigger"></div>




                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Footer setModalShow={setModalShow}></Footer>
        </>
    );
}

export default PrivacyPolicy;
