import Header from "../components/header";
import Homefooter from "../components/homefooter";
import Usescript from "../utils/usescript";
import Arrow from '../assets/images/arrow-right.svg'

function Home() {
  Usescript([
    "./assets/javascript/masthead.js?v=" + Date.now(),
    "./assets/javascript/waypoints-subnavs.js?v=" + Date.now(),
    "./assets/javascript/waypoints-subnav_hero.js?v=" + Date.now(),
    "./assets/javascript/subnav_select.js?v=" + Date.now(),
    "./assets/javascript/settings__tinyNav.js?v=" + Date.now(),
  ]);

  return (
    <>
      <Header></Header>
      <main id="mainContent">
        <div className="main_content">
          <div className="hero-short hero-short__noSubnav">
            <bgImage className="bgImage__img-4"></bgImage>
          </div>
          <div id="our-approach" className="waypoint__subnav"></div>
          <div className="section__bg-4">
            <div className="container">
              <div className="type-writter" id="typewriting"></div>
              <div className="type-writter" id="typewriting2"></div>
              <h3 className="underline"></h3>
              <div className="row">
                <div className="col span_1_of_1" id="investing-principle">
                  <div className="sub-header">
                    GUIDING INVESTMENT PRINCIPLE BEHIND OUR FUNDS{" "}
                  </div>
                  <p>
                    The SRH Funds are designed, implemented, and managed using a
                    simple investment thesis—invest in good businesses at
                    attractive valuations for the long-run. Our investment
                    thesis is agnostic about the type of investment vehicle used
                    and whether the portfolio is actively or passively managed.
                    Accordingly, our funds are available as an actively-managed 
                    closed-end fund, an actively managed ETF and a passively-managed ETF.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="contentEndTrigger"></div>
          <div id="tinyTitle">cont</div>
          <ul id="tinyNav">
            <li id="tinyNav_backtotop" cont="Back to Top">
              <img
                src={Arrow}
                width="40px"
                alt="move-top"
                height="40px"
                style={{ transform: "rotate(-90deg)" }}
              />
            </li>
          </ul>
        </div>
      </main>
      <Homefooter></Homefooter>
    </>
  );
}

export default Home;
