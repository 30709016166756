import React, { useState, useEffect } from "react";
import Fundheader from "../../../components/fundheader";
import Footer from "../../../components/STEW/footer";
import NAV from "../../../components/nav";
import { NAV_DAILY_DATA } from "../../../utils/queries";
import { useQuery } from "@apollo/client";

function PrivacyStatement() {
    const [modalShow, setModalShow] = useState(false);
    const [chartShow, setChartShow] = useState(false);
    const [dailyData, setDailyData] = useState(null);

    const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "STEW" }, });

    useEffect(() => {
        document.title = "SRH Total Return Fund - Privacy Statement";
    }, []);

    useEffect(() => {
        setDailyData(navData?.fund?.dailyData)
    }, [navData])

    return (
        <>
            <Fundheader fund="STEW"></Fundheader>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short">
                        <NAV dailyData={dailyData} bgImg={"bgImage__img-10"} title={'Privacy Statement'} ticker={"STEW"} />
                    </div>
                    <div className="container inner-content clearfix">
                        <div className="row">

                            <div className="col span_12_of_12">
                                <spacer></spacer>
                                <section>
                                    <h3 className="nomargin underline">Privacy Statement</h3>
                                    <p>Pursuant to SEC Regulation S-P (Privacy of Consumer Financial Information), the
                                        Directors of the SRH Total Return Fund, Inc. (the "Fund") have established
                                        the following policy regarding information about the Fund's shareholders. We
                                        consider all shareholder data to be private and confidential, and we hold ourselves
                                        to the highest standards in its safekeeping and use.</p>
                                </section>
                                <spacer></spacer>
                                <section>
                                    <h3 className="underline">General Statement</h3>
                                    <p>The Fund may collect nonpublic information (e.g., your name, address, email address,
                                        Social Security Number, Fund holdings (collectively, "Personal Information")) about
                                        shareholders from transactions in Fund shares. The Fund will not release Personal
                                        Information about current or former shareholders (except as permitted by law) unless
                                        one of the following conditions is met: (i) we receive your prior written consent;
                                        (ii) we believe the recipient to be you or your authorized representative; (iii) to
                                        service or support the business functions of the Fund (as explained in more detail
                                        below), or (iv) we are required by law to release Personal Information to the
                                        recipient. The Fund has not and will not in the future give or sell Personal
                                        Information about its current or former shareholders to any company, individual, or
                                        group (except as permitted by law) and as otherwise provided in this policy.</p>
                                    <p>In the future, the Fund may make certain electronic services available to its
                                        shareholders and may solicit your email address and contact you by email, telephone
                                        or US mail regarding the availability of such services. The Fund may also contact
                                        shareholders by email, telephone or US mail in connection with these services, such
                                        as to confirm enrollment in electronic shareholder communications or to update your
                                        Personal Information. In no event will the Fund transmit your Personal Information
                                        via email without your consent.</p>
                                </section>
                                <spacer></spacer>
                                <section>
                                    <h3 className="nomargin underline">Use of Personal Information</h3>
                                    <p>The Fund will only use Personal Information (i) as necessary to service or maintain
                                        shareholder accounts in the ordinary course of business and (ii) to support business
                                        functions of the Fund and its affiliated businesses. This means that the Fund may
                                        share certain Personal Information, only as permitted by law, with affiliated
                                        businesses of the Fund, and that such information may be used for non-Fund-related
                                        solicitation. When Personal Information is shared with the Fund's business
                                        affiliates, the Fund may do so without providing you the option of preventing these
                                        types of disclosures as permitted by law.</p>
                                </section>
                                <spacer></spacer>
                                <section>
                                    <h3 className="nomargin underline">Safeguards regarding Personal Information</h3>
                                    <p>Internally, we restrict access to Personal Information to those who have a specific
                                        need for the records. We maintain physical, electronic, and procedural safeguards
                                        that comply with federal standards to guard Personal Information. Any doubts about
                                        the confidentiality of Personal Information, as required by law, are resolved in
                                        favor of confidentiality.</p>
                                </section>
                                <spacer></spacer>
                                <spacer></spacer>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Footer setModalShow={setModalShow}></Footer>
        </>
    );
}

export default PrivacyStatement;
