import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import config from "./env";
import "./App.css";
import Contact from "./pages/contact";
import Fund from "./pages/SRHQ";
import Home from "./pages/home";
import About from "./pages/about";
import Articles from "./pages/articles";
import moment from "moment";
import SRHRFund from "./pages/SRHR";
import SRHQFund from "./pages/SRHQ";
import STEWFund from "./pages/STEW";
import PrivacyStatement from "./components/STEW/components/privayStatement";
import PrivacyPolicy from "./components/STEW/components/privacyPolicy";
import Disclosure from "./components/STEW/components/disclosure";
import TaxPage from "./components/STEW/components/taxpage";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  zIndex: 10000,
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 0 0 150vw #005695",
  fontWeight: "bold",
  flexDirection: "column",
};

const signal = {
  borderBottom: "8px solid #005695",
  color: "#005695",
  fontSize: "50px",
  width: "100%",
  textAlign: "center",
  padding: "25px",
  borderTop: "8px solid #005695",
};

const { graphQLEndpoint } = config;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: graphQLEndpoint,
});

function App() {
  let nowDate = moment.utc(new Date());
  let targetDate = moment.utc(new Date("2022-10-05T06:00:00.000-04:00"));
  // let targetDate = moment.utc(new Date("2022-10-04T10:52:00.000-04:00"));
  let difference = targetDate.diff(nowDate, "s");

  return (
    <>
      {difference > 0 && (
        <div style={{ ...modalStyle }} scroll="no">
          <p style={signal}>UNDER CONSTRUCTION</p>
          <br />
        </div>
      )}
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/srhq" element={<SRHQFund />} title="About Page" />
            <Route path="/srhr" element={<SRHRFund />} title="About Page" />
            <Route path="/stew" element={<STEWFund />} title="About Page" />
            <Route path="/stew/privacy-statement" element={<PrivacyStatement />} title="Privacy Statement Page" />
            <Route path="/stew/privacy-policy" element={<PrivacyPolicy />} title="Privacy Policy Page" />
            <Route path="/stew/disclosure" element={<Disclosure />} title="Disclosure Page" />
            <Route path="/stew/tax-documents" element={<TaxPage title="Tax Information" />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/articles" element={<Articles />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;
