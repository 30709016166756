import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RESOURCE_LITERATURE_DATA } from "../../utils/queries";
import config from "../../env/index.js";
import LinkedIn from '../../assets/images/linkedin.svg'

function Footer(props) {

  const { data, loading, error } = useQuery(RESOURCE_LITERATURE_DATA, {
    variables: {
      ticker: "SRHQ",
      documentTypes: [
        { "type": "prospectus" }
      ]
    }
  });

  const [prospectusFile, setProspectusFile] = useState();
  const [resourceEp, setResourceEp] = useState();

  const openTab = (link) => {
    window.open(link, '_blank')
  }

  useEffect(() => {
    let pro = data?.resources?.completeFundData?.at(0)?.funds?.at(0);
    setProspectusFile(pro);
    console.log("?>> prospectus footer", data, `${resourceEp}?id=${pro?.id}&ticker=${pro?.ticker}`);

    let resourceEndpoint = config.graphQLEndpoint.match("staging") ? "https://staging-funds.paralel.com/download_resource/" : "https://api-funds.paralel.com/download_resource/";
    setResourceEp(resourceEndpoint);
  }, [data]);

  return (
    <footer id="mainFooter">
      <div className="footer-nav"></div>

      <div className="container">
        <div className="disclosure">
          <p>
            <b>
              An investor should consider the investment objectives, risks,
              charges and expenses of the Fund carefully before investing. To
              obtain a prospectus containing this and other information, please
              call 1- 877-524-9155 or download the file from&nbsp;
              <a
                style={{ fontWeight: 600 }}
                target="_blank"
                href={`${resourceEp}?id=${prospectusFile?.id}&ticker=${prospectusFile?.ticker}`}
              >
                here
              </a>
              . Read the prospectus carefully before you invest.
            </b>
          </p>
          <h5>Fund Risks</h5>
          <p>
            The Fund’s investments are subject to equity market risk, which
            means that the value of securities held may experience sudden,
            unpredictable drops in value or long periods of decline in value due
            to various factors such as company-specific, sectors, government,
            and/or general economic and market conditions. Because the Fund
            invests in the securities included in the Index regardless of their
            investment merit, the Fund is subject to passive investment risk,
            which means that the Fund does not attempt to outperform the Index
            or take defensive positions in declining markets. The Fund is
            subject to limited history risk, which means there is limited
            history of operations for investors to evaluate. The Fund may not be
            successful in implementing its investment strategies or may fail to
            attract sufficient assets, any of which could result in the Fund
            being liquidated and terminated, an event that may result in
            negative tax consequences for shareholders and will cause
            shareholders to incur the expense of liquidation. The Fund is
            subject to ETF risks, such as, the risk that shares may trade at
            prices other than NAV when bought or sold in the secondary market.
            In addition, the Fund is subject to Index calculation methodology
            risk as it relates to the identification of Quality-, Growth-, and
            Value-style factors stocks. Although the Index uses a rules-based
            proprietary index methodology that is designed to identify growth
            companies with a higher quality profile that trade at a reasonable
            valuation level relative to the broader market, there is no
            guarantee that this methodology will be successful or that the past
            performance of these stocks will continue.
          </p>

          <p>
            The Fund employs a “passive management” - or indexing - investment
            approach and seeks investment results that correspond (before fees
            and expenses) generally to the performance of its underlying index.
            The Fund is not “actively” managed. Therefore, it would not
            necessarily sell or buy a security unless that security is removed
            from or added to the underlying index, respectively.
          </p>

          <p>
            Paralel Distributors LLC is the distributor of the SRH U.S. Quality ETF. Paralel Distributors LLC and Paralel Advisors LLC are wholly-owned subsidiaries of Paralel Technologies LLC. SLCT Holdings, LLC holds a non-controlling investment in Paralel Technologies LLC and is under common control with Rocky Mountain Advisers, LLC. Vident Advisory, LLC is not affiliated with Paralel Technologies LLC or Rocky Mountain Advisers, LLC
          </p>

          <a
            style={{
              textDecoration: "underline",
            }}
            onClick={() => props.setModalShow(true)}
          >
            <h5>Definitions</h5>
          </a>
        </div>
        <div className='footer-logo'>
          <img src={LinkedIn} onClick={() => openTab(`https://www.linkedin.com/company/srh-funds/`)} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
