import Header from '../components/header';
import Homefooter from '../components/homefooter';
import Usescript from "../utils/usescript";
import Arrow from '../assets/images/arrow-right.svg'

function About() {
    Usescript([
        "./assets/javascript/masthead.js",
        "./assets/javascript/waypoints-subnavs.js",
        "./assets/javascript/waypoints-subnav_hero.js",
        "./assets/javascript/subnav_select.js",
        "./assets/javascript/settings__tinyNav.js",
    ]);

    return (
        <>
            <Header></Header>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short hero-short__noSubnav">
                        <bgImage className="bgImage__img-4"></bgImage>
                    </div>
                    <div id="our-approach" className="waypoint__subnav"></div>
                    <div className="section__bg-4">
                        <div className="container">
                            <h3 className="underline text-center text-blue">About Us</h3>
                            <div className="row">
                                <div className="col span_1_of_1">
                                    <div className='about-header'>PARALEL ADVISORS LLC</div>
                                    <p>
                                        Paralel Advisors LLC serves as the investment adviser to the SRH Total Return Fund, the SRH U.S. Quality ETF, and the SRH REIT Covered Call ETF. Together with our parent company, Paralel Technologies LLC, we develop technology- based solutions to better serve investors.
                                    </p>
                                    <div className='partnership-header'>OUR PARTNERSHIP</div>
                                    <div className='about-header'>ROCKY MOUNTAIN ADVISERS, LLC</div>
                                    <p>Rocky Mountain Advisers, LLC, an Alaska limited liability company ("RMA"), is an SEC-registered investment adviser with an office in Kansas. RMA was formed in 2008 and began to actively conduct its investment advisory business in 2010. RMA serves as the sub-adviser to the SRH Total Return Fund and is the index provider for the SRH US Quality Index.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="contentEndTrigger"></div>
                    <div id="tinyTitle">cont</div>
                    <ul id="tinyNav">
                        <li id="tinyNav_backtotop" cont="Back to Top">
                            <img src={Arrow} width="40px" alt="move-top" height="40px" style={{ transform: "rotate(-90deg)" }} /></li>
                    </ul>
                </div>
            </main>
            <Homefooter></Homefooter>
        </>
    )
}

export default About;