import React from "react";
import moment from "moment";

function NAV(props) {
    const { dailyData, fundData = {}, title = '', bgImg, ticker = "" } = props

    return (
        <>
            <div className="">
                <div className="row hero_nav_header">
                    <div className="col span_10_of_10 lg-full">
                        <div className="container">
                            <span className="hero-short__title">
                                <strong>{title}</strong>
                            </span>
                        </div>
                        <div
                            id="dailyPriceTable"
                        >
                            <div className={`dataBox ${ticker == "STEW" ? 'stew' : ''}`} style={{ bottom: title !== "" ? '-70px' : '' }}>
                            {/* <div className="dataBox" style={{ bottom: title !== "" ? '-70px' : '' }}> */}
                                <div className="item">
                                    Ticker Symbol NYSE :<span className="ticker">{dailyData?.ticker ? dailyData?.ticker : ticker}</span>
                                </div>
                                <div className="item">
                                    Daily Data as of :
                                    <span className="formatedDate">{dailyData?.asOfDate ? moment(dailyData?.asOfDate)?.format('MM/DD/YYYY') : '-'}</span>
                                </div>
                                <div className="item">
                                    NAV : <span className="nav">{dailyData?.NAV ? '$' + dailyData?.NAV : '-'}</span>
                                </div>
                                <div className="item">
                                    Market Price : <span className="marketvalue">{dailyData?.marketPrice ? '$' + dailyData?.marketPrice : '-'}</span>
                                </div>
                                {ticker == 'STEW' ?
                                    <>
                                        <div className="item">
                                            Premium / Discount : <span className="nav">{(dailyData && dailyData?.premiumDiscount !== null) ? (dailyData.premiumDiscount*100).toFixed(2)+"%" : "-"}</span>
                                        </div>
                                        <div className="item">
                                            Distribution Rate : <span className="marketvalue">{dailyData?.distributionRate ? (dailyData?.distributionRate*100).toFixed(2)+"%" : "-"}</span>
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bgImage className={bgImg}></bgImage>
        </>
    );
}

export default NAV;
