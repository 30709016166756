import moment from "moment";
import { useEffect, useState } from "react";
import SelectDropdown from "../shared-components/SelectDropdown";

function Distributions(props) {
  const { distributionData, ticker } = props

  const [distributionList, SetDistributionList] = useState([])
  const [yearSelected, SetYearSelected] = useState(null)
  const [yearsOption, SetYearsOption] = useState([])
  const [distributionYearly, SetDistributionYearly] = useState([])

  useEffect(() => {
    let tableList = []
    if (distributionData?.length > 0) {
      let yearsArray = []
      let distributorList = Object.assign([], distributionData)
      distributorList.sort((a, b) => {
        return moment(b.exDate).diff(a.exDate);
      })?.map((item) => {
        const year = moment(item?.exDate).year();
        yearsArray.push(year);
        let obj = {
          amount: item?.amount ? '$' + item?.amount : '',
          declarationDate: item?.declarationDate,
          distributionType: item?.distributionType,
          exDate: item?.exDate ? moment(item?.exDate)?.format('MM/DD/YYYY') : '',
          payableDate: item?.payableDate ? moment(item?.payableDate)?.format('MM/DD/YYYY') : '',
          recordDate: item?.recordDate ? moment(item?.recordDate)?.format('MM/DD/YYYY') : '',
          sec19ANotice: item?.sec19ANotice ? item?.sec19ANotice : '',
        }
        tableList?.push(obj)
      })
      let uniqueList = [...new Set(yearsArray)]
      let yearList = []
      uniqueList?.map((year) => {
        yearList.push({
          label: year,
          value: year,
        })
      })
      if (yearList?.length > 0) {
        SetYearSelected(yearList[0])
      }
      SetYearsOption(yearList)
    }
    SetDistributionList(tableList)
  }, [distributionData])

  useEffect(() => {
    if (yearSelected) {
      let yearList = Object.assign([], distributionList)
      SetDistributionYearly(yearList?.filter((x) => moment(x.exDate)?.year() == yearSelected?.value))
    }
  }, [yearSelected, distributionList])

  const callSelectDropdown = (item) => {
    SetYearSelected(item)
  }


  return (
    <>
      <div className="section__bg-2">
        <div className="container clearfix">
          <div className="col span_1_of_1 lg-full">
            <section>
              <h3 className="underline">Distributions</h3>
              <waypoint id="distributionsWay"></waypoint>
              <div className="distributions-wrapper">
                <div id="distributions" className="waypoint__subnav"></div>
                <div className="row">
                  {/* <div className="col span_1_of_2 " style={{marginTop:"0", marginBottom:"0"}}>
                                <h6 style={{display:"inline"}}><span className="distributions-dropdown-year">2022</span>
                                </h6>
                            </div> */}
                  <div
                    className="col span_1_of_1"
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      textAlign: "right",
                    }}
                  >
                    <div className="dropdown_year_select_wrapper" style={{ width: '210px', marginLeft: 'auto' }}>
                      <SelectDropdown placeHolder={'SELECT A YEAR'} dropDownOptions={yearsOption} selectedValue={yearSelected} callSelectDropdown={callSelectDropdown} />
                    </div>
                  </div>
                </div>

                <div
                  id="distributionsTableWrapper"
                  className="tableHorizontalScroll"
                >
                  <table
                    id="table__distributions"
                    className="table--reduced-padding table__mod6"
                    style={{ marginBottom: "10px" }}
                  >
                    <tr>
                      <th style={{ textAlign: "center", minWidth: "160px" }}>Ex-Date</th>
                      <th style={{ textAlign: "center", minWidth: "160px" }}>Record Date</th>
                      <th style={{ textAlign: "center", minWidth: "160px" }}>Payable Date</th>
                      <th style={{ textAlign: "center", minWidth: "160px" }}>Amount</th>
                      <th style={{ textAlign: "center", minWidth: "160px" }}>Distribution Type</th>
                      {(ticker == "SRHR" || ticker == "STEW") ?
                        <th style={{ textAlign: "center", minWidth: "160px" }}>Section 19a Notices</th> : null
                      }
                    </tr>
                    {distributionYearly?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ textAlign: "center" }}>{item?.exDate}</td>
                          <td style={{ textAlign: "center" }}>{item?.recordDate}</td>
                          <td style={{ textAlign: "center" }}>{item?.payableDate}</td>
                          <td style={{ textAlign: "center" }}>{item?.amount}</td>
                          <td style={{ textAlign: "center" }}>{item?.distributionType}</td>
                          {(ticker == "SRHR" || ticker == "STEW") ?
                            <td style={{ textAlign: "center" }}>
                              {item?.sec19ANotice?.id ? <a href={`${process.env.REACT_APP_BACKEND_LINK}download_resource/?id=${item.sec19ANotice.id}&ticker=${item.sec19ANotice.ticker}`} target="_blank">{item.sec19ANotice.friendlyName}</a> : null}
                            </td> : null
                          }
                        </tr>
                      )
                    })}
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Distributions;
