import LinkedIn from '../../assets/images/linkedin.svg'

function Footer(props) {

  const openTab = (link) => {
    window.open(link, '_blank')
  }

  return (
    <footer id="mainFooter">
      <div className="footer-nav"></div>

      <div className="container">
        <div className="disclosure">
          <p>
            <b>
              An investor should consider the investment objectives, risks,
              charges and expenses of the Fund carefully before investing. To
              obtain a prospectus containing this and other information, please
              call 1- 877-524-9155 or download the file from&nbsp;
              <a
                style={{ fontWeight: 600 }}
                target="_blank"
                href="documents/srhr/srhr_prospectus.pdf"
              >
                here
              </a>
              . Read the prospectus carefully before you invest.
            </b>
          </p>
          <h5>Fund Risks</h5>
          <p>
            The Fund’s investments are subject to REIT risk, which means that the value of investments in REIT shares may decline because of adverse developments affecting the real estate industry and real property values. The Fund is also subject to real estate investment risks, which means that the Fund is subject to risks associated with the direct ownership of real estate securities and an investment in the Fund will be closely linked to the performance of the real estate markets. These risks include, among others: declines in the value of real estate; risks related to general and local economic conditions; possible lack of availability of mortgage funds or other limits to accessing the credit or capital markets; defaults by borrowers or tenants, particularly during an economic downturn; and changes in interest rates. Many real estate companies, including REITs, utilize leverage (and some may be highly leveraged), which increases investment risk and the risk normally associated with debt financing, and could potentially magnify the Fund’s losses. The Fund is subject to limited history risk, which means there is limited history of operations for investors to evaluate. The Fund may not be successful in implementing its investment strategies or may fail to attract sufficient assets, any of which could result in the Fund being liquidated and terminated, an event that may result in negative tax consequences for shareholders and will cause shareholders to incur the expense of liquidation. The Fund is subject to ETF risks, such as, the risk that shares may trade at prices other than NAV when bought or sold in the secondary market.
            The Fund is subject to derivatives risk since the Fund’s use of options involves risks different from, or possibly greater than, the risks associated with investing directly in securities and other traditional investments. These risks include (i) the risk that the counterparty to a derivative transaction may not fulfill its contractual obligations; (ii) the risk of mispricing or improper valuation; and (iii) the risk that changes in the value of an option may not correlate perfectly with the underlying asset, rate or index. Derivative prices may be highly volatile at times and may fluctuate substantially during a short period of time.
          </p>

          <p>
          The Fund is subject to active management risk. Rocky Mountain Advisers’ (RMA), a sub-adviser to the Fund responsible for the management of the strategy, judgments about the growth, value or potential appreciation of an investment may prove to be incorrect or fail to have the intended results, which could adversely impact the Fund’s performance and cause it to underperform relative to its benchmark, or not to achieve its investment objective.
          </p>

          <p>
            Paralel Distributors LLC is the distributor of the SRH U.S. Quality ETF and SRH REIT Covered Call ETF. Paralel Distributors LLC and Paralel Advisors LLC are wholly-owned subsidiaries of Paralel Technologies LLC. SLCT Holdings, LLC holds a non-controlling investment in Paralel Technologies LLC and is under common control with Rocky Mountain Advisers, LLC. Vident Advisory, LLC is not affiliated with Paralel Technologies LLC or Rocky Mountain Advisers, LLC.
          </p>

          <a
            style={{
              textDecoration: "underline",
            }}
            onClick={() => props.setModalShow(true)}
          >
            <h5>Definitions</h5>
          </a>
        </div>
        <div className='footer-logo'>
          <img src={LinkedIn} onClick={() => openTab(`https://www.linkedin.com/company/srh-funds/`)} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
