import React, { useState, useEffect } from "react";
import Overview from "../../components/SRHQ/overview";
import Distributions from "../../components/distributions";
import Performance from "../../components/performance";
import Holdings from "../../components/holdings";
import Literature from "../../components/SRHQ/literature";
import Usescript from "../../utils/usescript";
import Definitions from "../../modals/definitions";
import Premium from "../../modals/premium";
import Fundheader from "../../components/fundheader";
import Footer from "../../components/SRHQ/footer";
import { HOLDINGS_DATA, NAV_DAILY_DATA, PREMIUM_DATA, SECTOR_DATA } from "../../utils/queries";
import SelectDropdown from "../../shared-components/SelectDropdown";
import { useQuery } from "@apollo/client";
import moment from "moment";
import NAV from "../../components/nav";

function SRHQFund() {
  const [modalShow, setModalShow] = useState(false);
  const [chartShow, setChartShow] = useState(false);
  const [dailyData, setDailyData] = useState(null);
  const [fundData, setFundData] = useState(null);
  const [activeNav, setActiveNav] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const [fullHoldingsData, setFullHoldingsData] = useState(null);
  const [monthlyPerformance, setMonthlyPerformance] = useState(null);
  const [indexMonthly, setIndexMonthly] = useState();
  const [quarterlyPerformance, setQuarterlyPerformance] = useState(null);
  const [indexQuarterly, setIndexQuarterly] = useState();
  const [sectorAllocation, setSectorAllocation] = useState(null);
  const [distributionData, setDistributionData] = useState(null);
  const [premiumCount, setPremiumCount] = useState(null);
  const [premiumChart, setPremiumChart] = useState(null);

  const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "SRHQ" }, });
  const { loading: premiumLoading, error: premiumError, data: premiumData } = useQuery(PREMIUM_DATA, { variables: { ticker: "SRHQ", startDate: "2022-04-10", endDate: moment(new Date()).format('YYYY-MM-DD') } });
  const { loading: sectorLoading, error: sectorError, data: sectorData } = useQuery(SECTOR_DATA, { variables: { ticker: "SRHQ" }, });
  const { loading: holdingsLoading, error: holdingsError, data: holdingsData } = useQuery(HOLDINGS_DATA, { variables: { ticker: "SRHQ" }, });

  const dropDownOptions = [
    { label: 'Overview', value: 'overview' },
    // { label: 'Advisor', value: 'advisor' },
    { label: 'Distributions', value: 'distributions' },
    { label: 'Performance', value: 'performance' },
    { label: 'Holdings', value: 'holdings' },
    { label: 'Literature', value: 'literature' },
  ]

  useEffect(() => {
    document.title = "SRH US Quality ETF Fund - Home";
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      window?.pageYOffset > 300 ? setScrollActive(true) : setScrollActive(false))
  }, [])

  useEffect(() => {
    setDailyData(navData?.fund?.dailyData)
    setFundData(navData?.fund?.fundCharacteristics)
    setFullHoldingsData(holdingsData?.fund?.dataLakeDailyFullHoldings)
    setMonthlyPerformance(navData?.fund?.monthlyPerformance)
    setIndexMonthly(navData?.fund?.indexMonthlyPerformance)
    setQuarterlyPerformance(navData?.fund?.quarterlyPerformance)
    setIndexQuarterly(navData?.fund?.indexQuarterlyPerformance)
    setSectorAllocation(sectorData?.fund?.industryAllocation)
    setDistributionData(navData?.fund?.distribution)
    setPremiumCount(premiumData?.fund?.premiumDiscountCount)
    setPremiumChart(premiumData?.fund?.premiumDiscountChart)
  }, [navData, holdingsData, premiumData, sectorData])

  const scrollToView = (key) => {
    const section = document.getElementById(key);
    section.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const callSelectDropdown = (key) => {
    setActiveNav(key)
    scrollToView(key?.value)
  }



  return (
    <>
      <Fundheader fund="SRHQ"></Fundheader>
      <main id="mainContent">
        <div className="main_content">
          <div className="hero-short">
            <NAV dailyData={dailyData} bgImg={"bgImage__img-5"} ticker={"SRHQ"} />
            <div className={`subnav__wrapper ${scrollActive ? 'subnav__wrapper__fixed' : ''}`}>
              <div className="container">
                <div className="col span_1_of_1 subnav-column">
                  <ul className="subnav">
                    {dropDownOptions?.map((item) => {
                      return (
                        <li className={`subnav__item subnav__overview ${activeNav?.label == item?.label ? 'active' : ''}`}>
                          <div onClick={() => { setActiveNav(item); scrollToView(item?.value) }} className="subnav__link">
                            {item?.label}
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className={`mobile_subnav_wrapper ${scrollActive ? 'mobile_subnav_wrapper__fixed' : ''}`}>
              <SelectDropdown large={true} mobileView={true} dropDownOptions={dropDownOptions} selectedValue={activeNav} callSelectDropdown={callSelectDropdown} />
            </div>
          </div>
          <Overview fullHoldingsData={fullHoldingsData} setChartShow={setChartShow} dailyData={dailyData} fundData={fundData}></Overview>
          <Distributions distributionData={distributionData} ticker={"SRHQ"}></Distributions>
          <Performance monthlyPerformance={monthlyPerformance} quarterlyPerformance={quarterlyPerformance} indexMonthly={indexMonthly} indexQuarterly={indexQuarterly} ticker="SRHQ" />
          <Holdings fullHoldingsData={fullHoldingsData} sectorAllocation={sectorAllocation} />
          <Literature></Literature>
        </div>
      </main>
      <Footer setModalShow={setModalShow}></Footer>
      <Definitions show={modalShow} onHide={() => setModalShow(false)} />
      {/* <Premium show={chartShow} onHide={() => setChartShow(false)} fund={'SRHQ'} /> */}

      <Premium show={chartShow} startDate={dailyData?.inceptionDate} chartData={premiumChart} countData={premiumCount} onHide={() => setChartShow(false)} fund={'SRHQ'} />
    </>
  );
}

export default SRHQFund;
