import moment from "moment";
import { useEffect, useState } from "react";

function Performance(props) {
  const { monthlyPerformance, quarterlyPerformance, ticker, indexMonthly = [], indexQuarterly = [] } = props
  const AVG_ANNUALIZED = 'Avg Annualized'
  const CUMULATIVE = 'Cumulative'
  const [activePerformance, setActivePerformance] = useState(AVG_ANNUALIZED)

  const getIndexValue = (key, data, type = null) => {
    let filterData = type ? (data?.length > 0 && data?.filter((x) => x.indexName == type)?.length > 0) ? data?.filter((x) => x.indexName == type)[0] : null : data?.length > 0 ? data[0] : null
    return (filterData && filterData?.[key]) ? (filterData?.[key] * 100)?.toFixed(2) + '%' : '-';
  }

  const getValidNumber = (value) => {
    return (value !== undefined && value !== null) ? true : false
  }

  const performanceHeader = {
    'STEW': {
      cumulative: ['1 Month', '3 Month', 'Year to Date'],
      annual: ['1 Year', '3 Year', '5 Year', '10 Year', '20 Year']
    },
    'SRHQ': {
      cumulative: ['1 Month', '3 Month', 'Year to Date', 'Since Inception'],
      annual: ['1 Year', '3 Year', '5 Year', 'Since Inception'],
    },
    'SRHR': {
      cumulative: ['1 Month', '3 Month', 'Year to Date', 'Since Inception'],
      annual: ['1 Year', '3 Year', '5 Year', 'Since Inception'],
    }
  }
  const performanceList = {
    'STEW': [
      {
        title: 'Fund NAV',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthNav) ? (monthlyPerformance?.oneMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthNav) ? (monthlyPerformance?.threeMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdNav) ? (monthlyPerformance?.ytdNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearNav) ? (quarterlyPerformance?.oneYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearNav) ? (quarterlyPerformance?.threeYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearNav) ? (quarterlyPerformance?.fiveYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.tenYearNav) ? (quarterlyPerformance?.tenYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.twentyYearNav) ? (quarterlyPerformance?.twentyYearNav * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'Fund Market Price',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthMarket) ? (monthlyPerformance?.oneMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthMarket) ? (monthlyPerformance?.threeMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdMarket) ? (monthlyPerformance?.ytdMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearMarket) ? (quarterlyPerformance?.oneYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearMarket) ? (quarterlyPerformance?.threeYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearMarket) ? (quarterlyPerformance?.fiveYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.tenYearMarket) ? (quarterlyPerformance?.tenYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.twentyYearMarket) ? (quarterlyPerformance?.twentyYearMarket * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'Morningstar U.S. Large Value Index',
        performance: [
          getIndexValue('oneMonth', indexMonthly),
          getIndexValue('threeMonth', indexMonthly),
          getIndexValue('ytd', indexMonthly),
          getIndexValue('oneYear', indexQuarterly),
          getIndexValue('threeYear', indexQuarterly),
          getIndexValue('fiveYear', indexQuarterly),
          getIndexValue('tenYear', indexQuarterly),
          getIndexValue('twentyYear', indexQuarterly),
        ]
      },
      {
        title: 'S&P 500 Index',
        performance: [
          getIndexValue('oneMonth', indexMonthly, 'S&P 500 Total Return Index'),
          getIndexValue('threeMonth', indexMonthly, 'S&P 500 Total Return Index'),
          getIndexValue('ytd', indexMonthly, 'S&P 500 Total Return Index'),
          getIndexValue('oneYear', indexQuarterly, 'S&P 500 Total Return Index'),
          getIndexValue('threeYear', indexQuarterly, 'S&P 500 Total Return Index'),
          getIndexValue('fiveYear', indexQuarterly, 'S&P 500 Total Return Index'),
          getIndexValue('tenYear', indexQuarterly, 'S&P 500 Total Return Index'),
          getIndexValue('twentyYear', indexQuarterly, 'S&P 500 Total Return Index'),
        ]
      }
    ],
    'SRHQ': [
      {
        title: 'Fund NAV',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthNav) ? (monthlyPerformance?.oneMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthNav) ? (monthlyPerformance?.threeMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdNav) ? (monthlyPerformance?.ytdNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.siNav) ? (monthlyPerformance?.siNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearNav) ? (quarterlyPerformance?.oneYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearNav) ? (quarterlyPerformance?.threeYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearNav) ? (quarterlyPerformance?.fiveYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.s1Nav) ? (quarterlyPerformance?.s1Nav * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'Fund Market Price',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthMarket) ? (monthlyPerformance?.oneMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthMarket) ? (monthlyPerformance?.threeMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdMarket) ? (monthlyPerformance?.ytdMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.siMarket) ? (monthlyPerformance?.siMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearMarket) ? (quarterlyPerformance?.oneYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearMarket) ? (quarterlyPerformance?.threeYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearMarket) ? (quarterlyPerformance?.fiveYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.s1Market) ? (quarterlyPerformance?.s1Market * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'SRH U.S. Quality Index',
        performance: [
          getIndexValue('oneMonth', indexMonthly),
          getIndexValue('threeMonth', indexMonthly),
          getIndexValue('ytd', indexMonthly),
          getIndexValue('sinceInceptionCumulative', indexMonthly),
          getIndexValue('oneYear', indexQuarterly),
          getIndexValue('threeYear', indexQuarterly),
          getIndexValue('fiveYear', indexQuarterly),
          getIndexValue('sinceInceptionAnnualized', indexQuarterly)
        ]
      }
    ],
    'SRHR': [
      {
        title: 'Fund NAV',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthNav) ? (monthlyPerformance?.oneMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthNav) ? (monthlyPerformance?.threeMonthNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdNav) ? (monthlyPerformance?.ytdNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.siNav) ? (monthlyPerformance?.siNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearNav) ? (quarterlyPerformance?.oneYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearNav) ? (quarterlyPerformance?.threeYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearNav) ? (quarterlyPerformance?.fiveYearNav * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.s1Nav) ? (quarterlyPerformance?.s1Nav * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'Fund Market Price',
        performance: [
          getValidNumber(monthlyPerformance?.oneMonthMarket) ? (monthlyPerformance?.oneMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.threeMonthMarket) ? (monthlyPerformance?.threeMonthMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.ytdMarket) ? (monthlyPerformance?.ytdMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(monthlyPerformance?.siMarket) ? (monthlyPerformance?.siMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.oneYearMarket) ? (quarterlyPerformance?.oneYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.threeYearMarket) ? (quarterlyPerformance?.threeYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.fiveYearMarket) ? (quarterlyPerformance?.fiveYearMarket * 100)?.toFixed(2) + '%' : '-',
          getValidNumber(quarterlyPerformance?.s1Market) ? (quarterlyPerformance?.s1Market * 100)?.toFixed(2) + '%' : '-',
        ]
      },
      {
        title: 'Morningstar US Real Estate Index',
        performance: [
          getIndexValue('oneMonth', indexMonthly),
          getIndexValue('threeMonth', indexMonthly),
          getIndexValue('ytd', indexMonthly),
          getIndexValue('sinceInceptionCumulative', indexMonthly),
          getIndexValue('oneYear', indexQuarterly),
          getIndexValue('threeYear', indexQuarterly),
          getIndexValue('fiveYear', indexQuarterly),
          getIndexValue('sinceInceptionAnnualized', indexQuarterly)
        ]
      }
    ],
  }


  return (
    <>
      <div className="section__bg-2">
        <div className="container clearfix">
          <div className="col span_1_of_1 lg-full">
            <section>
              <h3 className="underline">Performance</h3>
              <div id="performanceTableWrapper">
                <div id="performance" className="waypoint__subnav"></div>
                <div id="tabs">
                  <div class="">
                    <div
                      class="tableHorizontalScroll"
                    >
                      <table
                        class="table--mod-4"
                      >
                        <tr>
                          <th>
                          </th>
                          <th colSpan={performanceHeader[ticker]?.cumulative?.length}>
                            <span>Cumulative (%)</span>
                            <span className="asofdate">
                              {monthlyPerformance?.asOfDate ?
                                `(As of ${moment(monthlyPerformance?.asOfDate)?.format('MM/DD/YYYY')})` :
                                null}
                            </span>
                          </th>
                          <th colSpan={performanceHeader[ticker]?.annual?.length}>
                            <span>Annualized (%)</span>
                            <span className="asofdate">
                              {quarterlyPerformance?.asOfDate ?
                                `(As of ${moment(quarterlyPerformance?.asOfDate)?.format('MM/DD/YYYY')})` :
                                null}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            {/* <span class="asofdate">
                              As of quarter-end
                              <date
                                class="ann_asofdate"
                              >
                                {quarterlyPerformance?.asOfDate ? moment(quarterlyPerformance?.asOfDate)?.format('(MM/DD/YYYY)') : '09/30/2023'}
                              </date>
                            </span> */}
                          </th>
                          {performanceHeader[ticker]?.cumulative?.map((data, idx) => {
                            return (
                              <th key={idx}
                              style={{ borderRight: idx == performanceHeader[ticker]?.cumulative?.length - 1 ? "2px solid #dfdfdf" : "none" }}
                              >{data}</th>
                            )
                          })}
                          {performanceHeader[ticker]?.annual?.map((data, idx) => {
                            return (
                              <th>{data}</th>
                            )
                          })}
                        </tr>
                        <tbody>
                          {performanceList[ticker]?.map((data, idx) => {
                            return (
                              <tr>
                                <td>{data?.title}</td>
                                {data?.performance?.map((value, index) => (
                                  <td
                                    style={{ 
                                       borderLeft: index == performanceHeader[ticker]?.cumulative?.length ? "2px solid #dfdfdf" : "none" }}>
                                    {value}
                                  </td>
                                ))}
                              </tr>
                            )
                          })}
                          <tr>
                          </tr>
                        </tbody>
                      </table>
                      <spacer></spacer>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="row">
              {ticker == "STEW" ?
                <div className="disclosure">
                  <p>
                    <b>
                      Performance data quoted represents past performance. Past
                      performance does not guarantee future results. The investment
                      return and principal value of an investment will fluctuate so
                      that an investor’s shares, when sold or redeemed, may be worth
                      more or less than their original cost. Current performance may
                      be lower or higher than the performance quoted.
                    </b>
                    For periods greater than one year, performance data is annualized.
                  </p>
                  <p>Fund inception date is January 31, 2002</p>
                  <p>Market Price is the price that the fund closed at on the primary listing exchange on the as of date referenced above.</p>
                  <p>NAV is the net asset value of the fund on the as of date referenced above and equals the value of all the fund's assets (less liabilities) divided by the number of shares outstanding.</p>
                  <p>Premium/Discount is the percentage difference between the net asset value of a fund and the market price.</p>
                  <p>The Morningstar US Large Value Index measures the performance of U.S. large-cap stocks with relatively low prices given anticipated per-share earnings, book value, cash flow, sales and dividends.</p>
                  <p>S&P 500® Total Return Index (SPTR): A market value weighted index of 500 stocks chosen for market size, liquidity and industry grouping, among other factors. This index is designed to be a leading indicator of U.S. equities and is meant to reflect the risk/return characteristics of the large cap universe. This index reflects the effects of dividend reinvestment.</p>
                </div>
                :
                <div className="disclosure">
                  {ticker == "SRHR" &&
                    <p>
                      <b>
                        The Morningstar US Real Estate Index is designed to measure the performance of mortgage companies, property management companies, and REITs in the United States. Indexes are unmanaged and, therefore, have no fees. Investors cannot invest directly in an index.
                      </b>
                    </p>
                  }
                  <p>
                    <b>
                      Performance data quoted represents past performance. Past
                      performance does not guarantee future results. The investment
                      return and principal value of an investment will fluctuate so
                      that an investor’s shares, when sold or redeemed, may be worth
                      more or less than their original cost. Current performance may
                      be lower or higher than the performance quoted.
                    </b>
                  </p>
                  <p>
                    Shares of ETFs are bought and sold at market price (not NAV) and
                    are not individually redeemed from the Fund. Market returns are
                    based upon the midpoint of the bid/ask spread at 4:00 p.m.
                    Eastern time (when NAV is normally determined for most ETFs),
                    and do not represent the returns you would receive if you traded
                    shares at other times. Ordinary brokerage commissions may apply
                    and will reduce returns.
                  </p>
                  <p>
                    Returns include reinvestment of dividends and capital gains.
                  </p>
                  {ticker == 'SRHQ' ?
                    <p>
                      The Fund commenced operations on October 4, 2022 and first traded on the exchange on October 5, 2022.
                    </p> : ticker == 'SRHR' ?
                      <p>
                        The Fund commenced operations on November 1, 2023 and first traded on the exchange on November 2, 2023.
                      </p> : null
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Performance;
