import LinkedIn from '../../assets/images/linkedin.svg'

function Footer(props) {

  const openTab = (link) => {
    window.open(link, '_blank')
  }

  return (
    <footer id="mainFooter">
      <div className="footer-nav srh-nav">
        <div className="container clearfix">
          <div className="row ">
            <div className="col">
              <div className="flex-grid" style={{ width: "auto" }}>
                <ul className="col">
                  <li><a href="/stew/privacy-statement">Privacy Statement</a></li>
                  <li><a href="/stew/disclosure">Disclosure</a></li>
                  <li><a href="/stew/privacy-policy">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="disclosure">
          <spacer></spacer>
          <spacer></spacer>
          <p>The performance data quoted represents past performance. Past performance is no guarantee of future
            results. Fund returns include reinvested dividends and distributions, but do not reflect the
            reduction of taxes that a stockholder would pay on Fund distributions or the sale of Fund shares and
            do not reflect brokerage commissions, if any. Returns of the S&amp;P 500 Index, the Dow Jones Industrial
            Average and the NASDAQ Composite include reinvested dividends and distributions, but do not reflect
            the effect of commissions, expenses or taxes, as applicable. You cannot invest directly in any of
            these indices. The investment return and the principal value of an investment will fluctuate and
            shares, if sold, may be worth more or less than their original cost. Current performance may be
            lower or higher than the performance data quoted.
          </p><p>The information on this website is for U.S. residents only and does not constitute an offer to sell,
            or a solicitation of an offer to purchase, securities in any jurisdiction to any person to whom it
            is not lawful to make such an offer.</p>
          <p><b>An investor should carefully consider the Fund's investment objectives, risk factors and expenses
            before investing. The Fund's proxy statements and annual reports contain this and other
            information about the Fund and should be read carefully before investing.</b></p>
          <p>An investment in the Fund involves risk, including loss of principal. There is no assurance the Fund
            will meet its investment objective.</p>
          <p>The Fund is a closed-end investment company traded on the New York Stock Exchange and does not
            continuously offer shares.</p>
          <p>Not FDIC Insured | May Lose Value | No Bank Guarantee.</p>
          <p>NOTHING IN THIS WEBSITE IS INTENDED TO BE, AND YOU SHOULD NOT CONSIDER ANYTHING IN THIS WEBSITE TO
            BE, INVESTMENT, ACCOUNTING, TAX OR LEGAL ADVICE. IF YOU WOULD LIKE INVESTMENT, ACCOUNTING, TAX OR
            LEGAL ADVICE, YOU SHOULD CONSULT WITH YOUR OWN FINANCIAL ADVISORS, ACCOUNTANTS, OR ATTORNEYS
            REGARDING YOUR INDIVIDUAL CIRCUMSTANCES AND NEEDS.</p>

          <p>Information on this website is provided "AS IS" without warranty of any kind, either express or
            implied, including, but not limited to, the implied warranties of merchantability, fitness for a
            particular purpose, or non-infringement. The data set forth on this website is for informational
            purposes only, and no warranty is made that the information is error-free.</p>

          <p>©2024 - Paralel Advisors LLC - All rights reserved.</p>
        </div>
        <div className='footer-logo'>
          <img src={LinkedIn} onClick={() => openTab(`https://www.linkedin.com/company/srh-funds/`)} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
