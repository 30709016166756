import styled from "styled-components";

export const DropdownWrapper = styled.div`
appearance: none;
box-sizing: border-box;
border-radius: 50px;
min-width: auto;
height: 31.5px;
font-size: 1.2rem;
text-transform: none;
overflow: visible;
color: #000;
cursor: pointer;
padding: 6px 12px;
margin: 12px auto;
background-color: #fff;
border: 1px solid #6b9dc1;
width: ${({ large }) => (large ? "80%" : "200px")};
&.mobile {
    @media (max-width: 992px) {
        display: ${({ mobileView }) => (mobileView ? "block" : "none")};
    }
}
`;
export const SelectionHeader = styled.div`
display: flex;
justify-content: space-between;
position: relative;
.title {
    text-align: left;
}
.select-icon{
    position: relative;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    margin-top: 4px;
    ::before {
        content: "";
        height: 50%;
        width: 50%;
        top: 0;
        left: 0;
        position: absolute;
        border-top: 2px solid black;
        border-left: 2px solid black;
        transition: all 0.6s;
    }
    ::after {
        content: "";
        height: 50%;
        width: 50%;
        bottom: 0;
        right: 0;
        position: absolute;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        transition: all 0.6s;
    }
}
`;
export const OptionContainer = styled.div`
width: calc(100% + 24px);
border-radius: 10px;
border: 1px solid #6b9dc1;
max-height: 200px;
overflow: auto;
margin-top: 10px;
margin-left: -10px;
background-color: #fff;
position: relative;
z-index: 10;
`;
export const SelectOption = styled.div`
padding: 1px 12px;
font-size: 14px;
text-align: left;
color: #000;
font-weight: ${({ active }) => (active ? "500" : "400")};
:hover {
    background: #005695;
    color: #fff
}
`;


