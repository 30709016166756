function Advisor(props) {
    const { dailyData, fundData } = props
    return (
        <>
            <div className="section__bg-2 hero_waypoint">
                <div className="container clearfix inner-content">
                    <div className="span_1_of_1">
                        <h3 className="underline">Advisor</h3>
                    </div>
                </div>
            </div>
            <div id="advisor" className="waypoint__subnav"></div>

            <div className="container">
                <div className="row"><div><p>Paralel Advisors serves as the investment adviser to the Fund. The strategy is managed by Rocky Mountain Advisers (RMA), a sub-adviser to the Fund.</p></div></div>
                <div className="row">
                    <div className="span_1_of_1">
                        <h5 class="underline">Portfolio Managers</h5>
                        <p></p>
                    </div>
                </div>
                <div className="">
                    <div class="container bioSection">
                        <div class="col span_1_of_2">
                            <h5 class="underline">Joel Looney, CFP</h5>
                            <p>Joel Looney, CFP, serves as President, Chief Investment Officer and Portfolio Manager for RMA. In these roles, Mr. Looney is responsible for supervising the overall advisory services provided by RMA, as well as for the day-to-day portfolio management of the SRH Total Return Fund. Mr. Looney is also an "investment adviser representative" who provides investment management and financial planning services to a group of RMA private clients.</p>
                            <p>Prior to joining RMA in 2013, Mr. Looney was the Principal for Financial Management Group, LLC, an investment management firm, from 1999 to 2013. Prior to forming Financial Management Group, LLC, he was the Chief Financial Officer for Bethany College in Lindsborg, KS from 1995 to 1999. Mr. Looney formerly served as Chairman of the Board of Directors for the Boulder Total Return Fund, The Denali Fund and the First Opportunity Fund. Mr. Looney currently serves as President and Chairman of the Board of Directors for the SRH Total Return Fund. Mr. Looney also serves as President of the Horejsi Charitable Foundation.</p>
                            <p>Mr. Looney holds an M.B.A from Kansas State University and a B.S. from Marymount College. Mr. Looney is also a CFP professional.</p>
                        </div>
                        <div class="col span_1_of_2">
                            <h5 class="underline">Jacob Hemmer, CFA</h5>
                            <p>Jacob Hemmer, CFA, serves as Vice President and Portfolio Manager for RMA. In this role Mr. Hemmer is responsible for providing investment research on current and potential holdings, as well as day-to-day portfolio management for the SRH Total Return Fund. Mr. Hemmer also manages a small number of private client accounts.</p>
                            <p>Prior to joining RMA in 2018, Mr. Hemmer was a Financial Advisor for Raymond James Financial Service, Inc dba. Hampton Financial from 2012 to 2018. Mr. Hemmer holds a B.S in Business from Colorado State University.</p>
                            <p>Mr. Hemmer is also a CFA charterholder.</p>
                        </div>
                    </div>
                    <br />
                    <p></p>
                </div>
            </div>
        </>
    );
}

export default Advisor;
