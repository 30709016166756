import moment from "moment";
import { isValidDate, numberWithCommas, toDecimal } from "../../utils";
import Arrow from '../../assets/images/proceed.svg'

function Overview(props) {
  const { dailyData, fundData, fullHoldingsData = [] } = props
  return (
    <>
      <div className="section__bg-2 hero_waypoint">
        <div className="container clearfix inner-content">
          <div className="col span_1_of_1">
            <h3 className="underline">Overview</h3>
          </div>
        </div>
      </div>
      <div id="overview" className="waypoint__subnav"></div>

      <div className="container">
        <div className="row">
          <div>
            <h5 className="underline">
              Reasons to Consider SRH REIT Covered Call ETF (SRHR)
            </h5>
            <ul>
              <li>
                <b>Income Potential</b> - Comprised of distribution growth-oriented REITs coupled with a covered call writing strategy on the individual REITs
              </li>
              <li>
                <b>U.S. REIT Exposure</b> - SRHR seeks to provide exposure to a portfolio of publicly traded REITs with long-term capital appreciation and annual distribution growth
              </li>
              <li>
                <b>Actively Managed</b> - The portfolio management team employs a fundamental, bottom-up, value-driven stock selection process
              </li>
            </ul>
            <br />
          </div>
        </div>
        <div className="row">
          <div>
            <h5 className="underline">
              Investment Objective & Strategy
            </h5>
            <p>
              The investment objective of the SRH REIT Covered Call ETF (the “Fund”) is to provide total return.
            </p>
            <p>
              Under normal circumstances, the Fund invests at least 80% of its net assets in Real Estate Investment Trusts (“REITs”) that are publicly traded on domestic stock exchanges. In addition, the Fund strategically implements an option strategy consisting of writing (selling) U.S. exchange-traded covered call options on the REITs in the Fund’s portfolio.
            </p>
          </div>
          <br />
        </div>
        <div className="row">
          {/* <div>
            <h5 className="underline">Investment Objective</h5>
            <p>
              The SRH U.S. Quality ETF (the “Fund”) seeks to provide investment
              results (before fees and expenses) that correspond to the SRH U.S.
              Quality Index (the “Index”).
            </p>
            <p>
              The Index is designed to track the performance of the stocks of
              domestic (U.S.) companies that have moderate and consistent
              revenue growth but do not trade at excessive valuations. Stocks
              are screened through value, growth, and quality metrics to
              determine eligibility in the Index. Indexes are unmanaged and one
              cannot invest directly in an index. Index performance returns do
              not reflect any management fees, transaction costs or expenses.
            </p>
          </div> */}
        </div>
        <div className="">
          <h5 className="underline">Fund Details</h5>
          <div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate">{dailyData?.asOfDate ? `As of ${moment(dailyData?.asOfDate)?.format('MM/DD/YYYY')}` : 'As of 11/01/2023'}</span>
                </div>
                <table className="table--mod-6">
                  <tr>
                    <td>Ticker Symbol</td>
                    <td>SRHR</td>
                  </tr>
                  {/* <tr>
                    <td>Index Ticker</td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td>CUSIP</td>
                    <td>{dailyData?.CUSIP || '210322301'}</td>
                  </tr>
                  <tr>
                    <td>Inception Date</td>
                    <td>{dailyData?.inceptionDate ? moment(dailyData?.inceptionDate)?.format('MM/DD/YYYY') : '11/01/2023'}</td>
                  </tr>
                  <tr>
                    <td>Expense Ratio</td>
                    <td>0.75%</td>
                  </tr>
                  <tr>
                    <td>Distribution Frequency</td>
                    <td>Monthly</td>
                  </tr>
                  <tr>
                    <td>Exchange</td>
                    <td>NYSE Arca</td>
                  </tr>
                </table>
              </section>
            </div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate-center">{fundData?.asOfDate ? `As of ${moment(isValidDate(fundData?.asOfDate))?.format('MM/DD/YYYY')}` : 'As of 11/01/2023'}</span>
                </div>
                <table className="table--mod-6 portfolio-table">
                  <tr>
                    <td>30 Day SEC Yield</td>
                    <td>{fundData?.thirtyDaySecYield ? (fundData?.thirtyDaySecYield * 100)?.toFixed(2) + '%' : '-'}</td>
                  </tr>
                  <tr>
                    <td>Distribution Yield</td>
                    <td>{fundData?.distributionYield ? (fundData?.distributionYield * 100)?.toFixed(2) + '%' : '-'}</td>
                  </tr>
                </table>
              </section>
            </div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate">{dailyData?.asOfDate ? `As of ${moment(dailyData?.asOfDate)?.format('MM/DD/YYYY')}` : 'As of 11/01/2023'}</span>
                </div>
                <table className="table--mod-6">
                  <tr>
                    <td>Total Net Assets</td>
                    <td>{dailyData?.totalNetAssets ? '$' + numberWithCommas(dailyData?.totalNetAssets) : '-'}</td>
                  </tr>
                  <tr>
                    <td>30 Day Median Bid/Ask Spread</td>
                    <td>{dailyData?.thirtyDayMedianSpread ? toDecimal(dailyData?.thirtyDayMedianSpread, 2) + '%' : '-'}</td>
                  </tr>
                  <tr>
                    <td>Number of Holdings</td>
                    <td>{fullHoldingsData?.length ? fullHoldingsData?.length : '-'}</td>
                  </tr>
                  <tr>
                    <td>Premium / Discount</td>
                    <td>{dailyData?.premiumDiscount ? toDecimal(dailyData?.premiumDiscount * 100, 2) + '%' : '0'}</td>
                  </tr>
                </table>
                <div>
                  <a
                    onClick={() => props.setChartShow(true)}
                    className="proceed-chart"
                  >
                    <img
                      src={Arrow}
                      height="15"
                      alt="logo"
                    />
                    <span className="view-chart">
                      View Premium/Discount Chart
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
