const defaultConfig = {}

const prodConfig = {
    graphQLEndpoint: 'https://api-funds.paralel.com/graphql/',
}

const uatConfig = {
    graphQLEndpoint: 'https://staging-funds.paralel.com/graphql/',
}

const { REACT_APP_ENV } = process.env;

let selectedConfig = {};

if (REACT_APP_ENV && REACT_APP_ENV.trim() === 'prod') {
    selectedConfig = prodConfig;
} else {
    selectedConfig = uatConfig;
}

const config = {
    ...defaultConfig,
    ...selectedConfig,
}

export default config;
