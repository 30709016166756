import { useEffect } from "react";

function Usescript(src) {
  useEffect(() => {
    if (src && src.length > 0) {
      src.map(function (item, i) {
        const script = document.createElement("script");
        script.src = item;
        script.async = false;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      });
    }
  }, [src]);
}

export default Usescript;
