import STEWLogo from '../assets/images/STEW_Logo.svg';
import SRHQLogo from '../assets/images/SRHQ_Logo.svg';
import SRHRLogo from '../assets/images/SRHR_Logo.svg';
import SRHLogo from '../assets/images/SRH_FUNDS_Logo.svg';

function Fundheader(props) {
  const { fund } = props
  return (
    <header id="mainHeader">
      <div className="full-width" id="navamongus">
        <div className="meta-nav__wrapper">
          <div className="container">
            <ul className="meta-nav">
              <li className="meta-nav__item">
                <a href="/" className="meta-nav__link">
                  Home
                </a>
              </li>
              <li className="meta-nav__item">
                <a href="/contact-us" className="meta-nav__link">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <mobileNavButton></mobileNavButton> */}
        <div className="nav_container">
          {fund == "SRHR" ?
            <a href="/srhr" className="site-logo">
              <img
                src={SRHRLogo}                
                alt="logo"
              />
            </a>
            : fund == "STEW" ?
              <a href="/stew" className="site-logo">
                <img
                  src={STEWLogo}                  
                  alt="logo"
                />
              </a> : fund == "SRHQ" ?
              <a href="/srhq" className="site-logo srhq">
                <img
                  src={SRHQLogo}           
                  alt="logo"
                />
              </a> :
               <a href="/" className="site-logo srh">
               <img
                 src={SRHLogo}           
                 alt="logo"
               />
             </a>
          }
        </div>
      </div>
      <script
        type="text/javascript"
        src="./assets/javascript/masthead.js"
      ></script>
    </header>
  );
}

export default Fundheader;
