import React, { useState, useEffect } from "react";
import Overview from "../../components/SRHR/overview";
import Distributions from "../../components/distributions";
import Performance from "../../components/performance";
import Holdings from "../../components/holdings";
import Literature from "../../components/SRHR/literature";
import Fundheader from "../../components/fundheader";
import Advisor from "../../components/SRHR/advisor";
import Footer from "../../components/SRHR/footer";
import Definitions from "../../modals/definitions";
import Premium from "../../modals/premium";
import { HOLDINGS_DATA, NAV_DAILY_DATA, PREMIUM_DATA, SECTOR_DATA } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import SelectDropdown from "../../shared-components/SelectDropdown";
import moment from "moment";
import NAV from "../../components/nav";

function SRHRFund() {
  const START_DATE = '2023-01-01'
  const [modalShow, setModalShow] = useState(false);
  const [chartShow, setChartShow] = useState(false);
  const [dailyData, setDailyData] = useState(null);
  const [fundData, setFundData] = useState(null);
  const [activeNav, setActiveNav] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const [fullHoldingsData, setFullHoldingsData] = useState(null);
  const [monthlyPerformance, setMonthlyPerformance] = useState(null);
  const [quarterlyPerformance, setQuarterlyPerformance] = useState(null);
  const [monthlyIndex, setMonthlyIndex] = useState(null);
  const [quarterlyIndex, setQuarterlyIndex] = useState(null);
  const [sectorAllocation, setSectorAllocation] = useState(null);
  const [distributionData, setDistributionData] = useState(null);
  const [premiumChart, setPremiumChart] = useState(null);
  const [premiumCount, setPremiumCount] = useState(null);
  const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "SRHR" }, });
  const { loading: sectorLoading, error: sectorError, data: sectorData } = useQuery(SECTOR_DATA, { variables: { ticker: "SRHR" }, });
  // const { loading: holdingsLoading, error: holdingsError, data: holdingsData } = useQuery(HOLDINGS_DATA, { variables: { ticker: "SRHR", startDate: "2023-09-01", endDate: "2023-09-30" }, });
  const { loading: holdingsLoading, error: holdingsError, data: holdingsData } = useQuery(HOLDINGS_DATA, { variables: { ticker: "SRHR" }, });
  const { loading: premiumLoading, error: premiumError, data: premiumData } = useQuery(PREMIUM_DATA, { variables: { ticker: "SRHR", startDate: "2023-11-01", endDate: moment(new Date())?.format("YYYY-MM-DD") }, });
  // const { loading: premiumLoading, error: premiumError, data: premiumData } = useQuery(PREMIUM_DATA, { variables: { ticker: "SRHR" }, });

  const dropDownOptions = [
    { label: 'Overview', value: 'overview' },
    { label: 'Advisor', value: 'advisor' },
    { label: 'Distributions', value: 'distributions' },
    { label: 'Performance', value: 'performance' },
    { label: 'Holdings', value: 'holdings' },
    { label: 'Literature', value: 'literature' },
  ]

  useEffect(() => {
    document.title = "SRH REIT Covered Call ETF - Home";
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      window?.pageYOffset > 300 ? setScrollActive(true) : setScrollActive(false))
  }, [])

  useEffect(() => {
    setDailyData(navData?.fund?.dailyData)
    setFundData(navData?.fund?.fundCharacteristics)
    setFullHoldingsData(holdingsData?.fund?.dataLakeDailyFullHoldings)
    setMonthlyPerformance(navData?.fund?.monthlyPerformance)
    setMonthlyIndex(navData?.fund?.indexMonthlyPerformance)
    setQuarterlyPerformance(navData?.fund?.quarterlyPerformance)
    setQuarterlyIndex(navData?.fund?.indexQuarterlyPerformance)
    setSectorAllocation(sectorData?.fund?.industryAllocation)
    setDistributionData(navData?.fund?.distribution)
    setPremiumCount(premiumData?.fund?.premiumDiscountCount)
    setPremiumChart(premiumData?.fund?.premiumDiscountChart)
  }, [navData, holdingsData, premiumData, sectorData])

  const scrollToView = (key) => {
    const section = document.getElementById(key);
    section.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const callSelectDropdown = (key) => {
    setActiveNav(key)
    scrollToView(key?.value)
  }

  return (
    <>
      <Fundheader fund="SRHR"></Fundheader>
      <main id="mainContent">
        <div className="main_content">
          <div className="hero-short">
            <NAV dailyData={dailyData} bgImg={"bgImage__img-5"} ticker={"SRHR"} />
            <div className={`subnav__wrapper ${scrollActive ? 'subnav__wrapper__fixed' : ''}`}>
              <div className="container">
                <div className="col span_1_of_1 subnav-column">
                  <ul className="subnav">
                    {dropDownOptions?.map((item) => {
                      return (
                        <li className={`subnav__item subnav__overview ${activeNav?.label == item?.label ? 'active' : ''}`}>
                          <div onClick={() => { setActiveNav(item); scrollToView(item?.value) }} className="subnav__link">
                            {item?.label}
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className={`mobile_subnav_wrapper ${scrollActive ? 'mobile_subnav_wrapper__fixed' : ''}`}>
              <SelectDropdown large={true} mobileView={true} dropDownOptions={dropDownOptions} selectedValue={activeNav} callSelectDropdown={callSelectDropdown} />
            </div>
            {/* <script src="./assets/javascript/waypoints-subnavs.js"></script>
            <script src="./assets/javascript/waypoints-subnav_hero.js"></script>
            <script src="./assets/javascript/subnav_select.js"></script> */}
          </div>
          <Overview setChartShow={setChartShow} fullHoldingsData={fullHoldingsData} dailyData={dailyData} fundData={fundData}></Overview>
          <Advisor />
          <Distributions distributionData={distributionData} ticker={"SRHR"}></Distributions>
          <Performance indexMonthly={monthlyIndex} indexQuarterly={quarterlyIndex} monthlyPerformance={monthlyPerformance} quarterlyPerformance={quarterlyPerformance} ticker="SRHR" />
          <Holdings fullHoldingsData={fullHoldingsData} sectorAllocation={sectorAllocation} />
          <Literature></Literature>
        </div>
      </main>
      <Footer setModalShow={setModalShow}></Footer>
      <Definitions show={modalShow} onHide={() => setModalShow(false)} />
      <Premium show={chartShow} startDate={START_DATE} chartData={premiumChart} countData={premiumCount} onHide={() => setChartShow(false)} fund={'SRHR'} />
    </>
  );
}

export default SRHRFund;
