import React, { useState, useEffect } from "react";
import Fundheader from "../../../components/fundheader";
import Footer from "../../../components/STEW/footer";
import NAV from "../../../components/nav";
import { NAV_DAILY_DATA } from "../../../utils/queries";
import { useQuery } from "@apollo/client";
import TaxInformation from "./taxinformation";

function TaxPage(props) {
    const { title } = props
    const [modalShow, setModalShow] = useState(false);
    const [chartShow, setChartShow] = useState(false);
    const [dailyData, setDailyData] = useState(null);

    const { loading: navLoading, error: navError, data: navData } = useQuery(NAV_DAILY_DATA, { variables: { ticker: "STEW" }, });


    useEffect(() => {
        setDailyData(navData?.fund?.dailyData)
    }, [navData])

    return (
        <>
            <Fundheader fund="STEW"></Fundheader>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short">
                        <NAV dailyData={dailyData} bgImg={"bgImage__img-12"} title={'Tax Information'} ticker={"STEW"} />
                    </div>
                    <TaxInformation />
                </div>
            </main>
            <Footer setModalShow={setModalShow}></Footer>
        </>
    );
}

export default TaxPage;
