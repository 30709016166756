import Header from '../components/header';
import Homefooter from '../components/homefooter';
import Usescript from "../utils/usescript";
import Arrow from '../assets/images/arrow-right.svg'

function Articles() {
    Usescript([
        "./assets/javascript/masthead.js",
        "./assets/javascript/waypoints-subnavs.js",
        "./assets/javascript/waypoints-subnav_hero.js",
        "./assets/javascript/subnav_select.js",
        "./assets/javascript/settings__tinyNav.js",
    ]);

    return (
        <>
            <Header></Header>
            <main id="mainContent">
                <div className="main_content">
                    <div className="hero-short hero-short__noSubnav">
                        <bgImage className="bgImage__img-4"></bgImage>
                    </div>
                    <div id="our-approach" className="waypoint__subnav"></div>
                    <div className="section__bg-4">
                        <div className="container">
                            <h3 className="underline text-center text-blue">Articles</h3>
                            <div className="row">
                                <div className="col span_1_of_1">
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="contentEndTrigger"></div>
                    <div id="tinyTitle">cont</div>
                    <ul id="tinyNav">
                        <li id="tinyNav_backtotop" cont="Back to Top">
                            <img src={Arrow} width="40px" alt="move-top" height="40px" style={{ transform: "rotate(-90deg)" }} /></li>
                    </ul>
                </div>
            </main>
            <Homefooter></Homefooter>
        </>
    )
}

export default Articles;