import React, { useEffect, useRef, useState } from "react";
import { DropdownWrapper, OptionContainer, SelectOption, SelectionHeader } from './style'

const SelectDropdown = (props) => {
    const { dropDownOptions, selectedValue, callSelectDropdown, large = false, mobileView = false, placeHolder = 'Select an Option'  } = props
    const [selectActive, setSelectActive] = useState(false);
    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setSelectActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <DropdownWrapper large={large} mobileView={mobileView} className={`${mobileView ? 'mobile' : ''}`}>
                <SelectionHeader onClick={() => setSelectActive(!selectActive)}>
                    <div className="title">{selectedValue?.label ? selectedValue?.label : placeHolder}</div>
                    <div className="select-icon"></div>
                </SelectionHeader>
                {selectActive &&
                    <OptionContainer ref={ref}>
                        {dropDownOptions?.length > 0 ?
                        dropDownOptions?.map((item) => {
                            return (
                                <SelectOption active={selectedValue?.label == item?.label ? true : false} onClick={() => {callSelectDropdown(item); setSelectActive(false)}}>{item?.label}</SelectOption>
                            )
                        }) :
                        <SelectOption onClick={() => setSelectActive(false)}>No Options</SelectOption>
                    }
                    </OptionContainer>
                }
            </DropdownWrapper>

        </>
    )
}

export default SelectDropdown;