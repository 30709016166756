import React, { useState, useEffect } from "react";

function TaxInformation() {

    useEffect(() => {
        document.title = "SRH Total Return Fund - Tax Information";
    }, []);

    return (
        <>
            <div className="container inner-content clearfix">
                <div className="row">

                    <div className="col span_12_of_12">
                        <spacer></spacer>
                        <section>
                            <h3 className="nomargin underline">SRH TOTAL RETURN FUND ANNOUNCES TAX INFORMATION</h3>
                            <p>Report of Organizational Actions Affecting Tax Basis</p>
                            <div>
                                <a href="/documents/stew/boulder-growth-income-fund-8937-20201130.pdf" target="_blank" className="docLink">
                                    <span>8937 Form - 2020</span>
                                </a>
                                <a href="/documents/stew/boulder-growth-income-fund-8937-20191130.pdf" target="_blank" className="docLink">
                                    <span>8937 Form - 2019</span>
                                </a>
                                <a href="/documents/stew/boulder-growth-income-fund-8937-20181130.pdf" target="_blank" className="docLink">
                                    <span>8937 Form - 2018</span>
                                </a>
                                <a href="/documents/stew/boulder-growth-income-fund-8937-20171130.pdf" target="_blank" className="docLink">
                                    <span>8937 Form - 2017</span>
                                </a>
                                <a href="/documents/stew/boulder-growth-income-fund-8937-20161130.pdf" target="_blank" className="docLink">
                                    <span>8937 Form - 2016</span>
                                </a>
                            </div>
                        </section>
                        <spacer></spacer>
                        <section>
                            <div className="row">

                                <h4 className="underline">2016 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2015
                                    common stock distributions as follows:</p>

                                <div id="tax2016_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>1/22/2016</td>
                                            <td>1/29/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>2/22/2016</td>
                                            <td>2/29/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>3/23/2016</td>
                                            <td>3/31/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>4/22/2016</td>
                                            <td>4/29/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>5/23/2016</td>
                                            <td>5/31/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>6/23/2016</td>
                                            <td>6/30/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>7/22/2016</td>
                                            <td>7/29/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>8/24/2016</td>
                                            <td>8/31/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>9/23/2016</td>
                                            <td>9/30/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>10/22/2016</td>
                                            <td>10/31/2016</td>
                                            <td>$0.03300</td>
                                            <td>$0.01708</td>
                                            <td>$0.00811</td>
                                            <td>0.00734</td>
                                            <td>0.00047</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2016</td>
                                            <td>11/30/2016</td>
                                            <td>$0.03400</td>
                                            <td>$0.01759</td>
                                            <td>$0.00836</td>
                                            <td>0.00756</td>
                                            <td>0.00049</td>
                                        </tr>
                                        <tr>
                                            <td>12/22/2016</td>
                                            <td>12/30/2016</td>
                                            <td>$0.03400</td>
                                            <td>$0.02305</td>
                                            <td>$0.01095</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2016_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">58.92% of the dividends paid qualify for corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>

                                <h4 className="underline">2015 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2015
                                    common stock distributions as follows:</p>

                                <div id="tax2015_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>11/23/2015</td>
                                            <td>11/30/2015</td>
                                            <td>$0.03300</td>
                                            <td>$0.03300</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>12/24/2015</td>
                                            <td>12/31/2015</td>
                                            <td>$0.12253</td>
                                            <td>$0.04713</td>
                                            <td>-</td>
                                            <td>$0.07540</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2015_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>


                                <h4 className="underline">2014 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2014
                                    common stock distributions as follows:</p>

                                <div id="tax2014_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>7/1/2014</td>
                                            <td>7/11/2014</td>
                                            <td>$0.04</td>
                                            <td>$0.02</td>
                                            <td>-</td>
                                            <td>$0.02702</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>12/22/2014</td>
                                            <td>12/30/2014</td>
                                            <td>$0.29417</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>$0.29417</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2014_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>


                                <h4 className="underline">2013 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2013
                                    common stock distributions as follows:</p>

                                <div id="tax2013_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>7/23/2013</td>
                                            <td>7/30/2013</td>
                                            <td>$0.05643</td>
                                            <td>$0.01713</td>
                                            <td>-</td>
                                            <td>$0.03930</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>12/31/2013</td>
                                            <td>1/10/2014</td>
                                            <td>$0.39223</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>$0.39223</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2013_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>


                                <h4 className="underline">2012 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2012
                                    common stock distributions as follows:</p>

                                <div id="tax2012_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>12/31/2012</td>
                                            <td>1/11/2013</td>
                                            <td>$0.29</td>
                                            <td>$0.14000</td>
                                            <td>-</td>
                                            <td>$0.15000</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2012_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">77.25% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>

                                <h4 className="underline">2011 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2011
                                    common stock distributions as follows:</p>

                                <div id="tax2011_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>12/30/2011</td>
                                            <td>1/11/2012</td>
                                            <td>$0.11</td>
                                            <td>$0.03646</td>
                                            <td>-</td>
                                            <td>$0.07354</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2011_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                                <spacer></spacer>


                                <h4 className="underline">2010 TAX INFORMATION</h4>
                                <p>The SRH Total Return Fund (NYSE:STEW) announced the final tax status for its 2010
                                    common stock distributions as follows:</p>

                                <div id="tax2010_TableWrapper" className="tableHorizontalScroll">
                                    <table className="table--mod-1">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colspan="2">Total Ordinary Dividends</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Record Date</th>
                                            <th>Payable Date</th>
                                            <th>Ammount per Share</th>
                                            <th>Qualified</th>
                                            <th>Non-Qualified</th>
                                            <th>Long-Term Capital Gains</th>
                                            <th>Return of Capital</th>
                                        </tr>
                                        <tr>
                                            <td>12/28/2010</td>
                                            <td>12/31/2010</td>
                                            <td>$0.10</td>
                                            <td>$0.05815</td>
                                            <td>-</td>
                                            <td>$0.04185</td>
                                            <td>-</td>
                                        </tr>
                                    </table>

                                </div>

                                <div id="tax2010_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                                    data &rarr;</div>

                                <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                                    deduction.</p>

                            </div>
                            {/* <div className="row">
                                        <div className="span_1_of_1">
                                            <h5 className="underline">Portfolio Managers</h5>
                                            <p></p>
                                        </div>
                                    </div> */}
                        </section>

                    </div>

                </div>
            </div>

            <div className="container inner-content clearfix">

                <div className="col span_1_of_1 ">

                    <spacer></spacer>
                    <h3 className="underline">Boulder Total Return Fund Announces Tax Information</h3>

                    <spacer></spacer>

                    <p>Report of Organizational Actions Affecting Tax Basis</p>

                    <a href="/documents/stew/bouldercef-btf-8937-20150320.pdf" target="_blank" className="docLink">
                        <span>8937 Form - 2015 Merger</span>
                    </a>


                    <spacer></spacer>

                    <h4 className="underline">2015 TAX INFORMATION</h4>
                    <p>The Boulder Total Return Fund (NYSE: BTF) announced the final tax status for its 2015 common
                        stock distributions as follows:</p>

                    <div id="tax2015_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>3/16/2015</td>
                                    <td>3/19/2015</td>
                                    <td>$0.10</td>
                                    <td>$0.03</td>
                                    <td>-</td>
                                    <td>$0.07</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2015_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2014 TAX INFORMATION</h4>
                    <p>The Boulder Total Return Fund (NYSE: BTF) announced the final tax status for its 2014 common
                        stock distributions as follows:</p>

                    <div id="tax2014_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/1/2014</td>
                                    <td>7/11/2014</td>
                                    <td>$0.02</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>$0.02</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>12/22/2014</td>
                                    <td>12/30/2014</td>
                                    <td>$0.24</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>$0.24</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2014_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2013 TAX INFORMATION</h4>
                    <p>The Boulder Total Return Fund (NYSE: BTF) announced the final tax status for its 2013 common
                        stock distributions as follows:</p>

                    <div id="tax2013_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/31/2013</td>
                                    <td>1/10/2014</td>
                                    <td>$0.08</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>$0.08</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2013_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2012 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2012.</p>

                    <spacer></spacer>

                    <h4 className="underline">2011 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2011.</p>

                    <spacer></spacer>

                    <h4 className="underline">2010 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2010.</p>

                    <spacer></spacer>

                    <h4 className="underline">2009 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2009.</p>

                    <spacer></spacer>


                    <h4 className="underline">2008 TAX INFORMATION</h4>
                    <p>The Boulder Total Return Fund (NYSE: BTF) announced the final tax status for its 2008 common
                        stock distributions as follows:</p>

                    <div id="tax2008_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>1/24/2008</td>
                                    <td>1/31/2008</td>
                                    <td>$0.2400</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>2/22/2008</td>
                                    <td>2/29/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>3/24/2008</td>
                                    <td>3/31/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>4/23/2008</td>
                                    <td>4/30/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>5/23/2008</td>
                                    <td>5/30/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>6/23/2008</td>
                                    <td>6/30/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>7/24/2008</td>
                                    <td>7/31/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>8/22/2008</td>
                                    <td>8/29/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>9/23/2008</td>
                                    <td>9/30/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>10/24/2008</td>
                                    <td>10/31/2008</td>
                                    <td>$0.2730</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>100.00%</td>
                                </tr>

                            </tbody></table>

                    </div>

                    <div id="tax2008_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2008 will be listed in Box 3 of your 1099-DIV.</p>

                </div>


            </div>

            <div className="container inner-content clearfix">

                <div className="col span_1_of_1 ">


                    <spacer></spacer>
                    <h3 className="underline">The Denali Fund Announces Tax Information</h3>

                    <spacer></spacer>

                    <p>Report of Organizational Actions Affecting Tax Basis</p>

                    <a href="/documents/stew/bouldercef-dny-8937-20150320.pdf" target="_blank" className="docLink">
                        <span>DNY 8937 Form - 2015 Merger</span>
                    </a>

                    <spacer></spacer>

                    <h4 className="underline">2015 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2015 distributions as
                        follows:</p>

                    <div id="tax2015_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>3/16/2015</td>
                                    <td>3/19/2015</td>
                                    <td>$0.70</td>
                                    <td>$0.15</td>
                                    <td>-</td>
                                    <td>$0.55</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2015_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2014 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2014 distributions as
                        follows:</p>

                    <div id="tax2014_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/1/2014</td>
                                    <td>7/11/2014</td>
                                    <td>$0.27</td>
                                    <td>$0.17</td>
                                    <td>-</td>
                                    <td>$0.10</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>12/22/2014</td>
                                    <td>12/30/2014</td>
                                    <td>$1.47</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>$1.47</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2014_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2013 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2013 distributions as
                        follows:</p>

                    <div id="tax2013_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/23/2013</td>
                                    <td>7/30/2013</td>
                                    <td>$0.16</td>
                                    <td>56.25%</td>
                                    <td>0.00%</td>
                                    <td>43.75%</td>
                                    <td>0.00%</td>
                                </tr>
                                <tr>
                                    <td>12/31/2013</td>
                                    <td>1/10/2014</td>
                                    <td>$0.75</td>
                                    <td>0.00%</td>
                                    <td>0.00%</td>
                                    <td>100%</td>
                                    <td>0.00%</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2013_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">100% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>




                    <h4 className="underline">2012 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2012 distributions as
                        follows:</p>

                    <div id="tax2012_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/24/2012</td>
                                    <td>7/31/2012</td>
                                    <td>$0.0150</td>
                                    <td>0.00%</td>
                                    <td>0.00%</td>
                                    <td>100.00%</td>
                                    <td>0.00%</td>
                                </tr>
                                <tr>
                                    <td>12/31/2012</td>
                                    <td>1/11/2013</td>
                                    <td>$0.2000</td>
                                    <td>25.00%</td>
                                    <td>0.00%</td>
                                    <td>75.00%</td>
                                    <td>0.00%</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2012_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2012 will be listed in Box 3 of your 1099-DIV. In addition, 100% of the dividends paid
                        qualify for the corporate dividends received deduction.</p>

                    <spacer></spacer>



                    <h4 className="underline">2011 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2011 distributions as
                        follows:</p>

                    <div id="tax2011_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/25/2011</td>
                                    <td>7/29/2011</td>
                                    <td>$0.2400</td>
                                    <td>28.96%</td>
                                    <td>29.37%</td>
                                    <td>41.67%</td>
                                    <td>0.00%</td>
                                </tr>
                                <tr>
                                    <td>12/30/2011</td>
                                    <td>1/11/2012</td>
                                    <td>$0.7800</td>
                                    <td>11.21%</td>
                                    <td>11.38%</td>
                                    <td>77.41%</td>
                                    <td>0.00%</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2011_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2011 will be listed in Box 3 of your 1099-DIV. In addition, 32.61% of the dividends paid
                        qualify for the corporate dividends received deduction.</p>

                    <spacer></spacer>



                    <h4 className="underline">2010 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2010 distributions as
                        follows:</p>

                    <div id="tax2010_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>7/23/2010</td>
                                    <td>7/30/2010</td>
                                    <td>$0.0300</td>
                                    <td>100.00%</td>
                                    <td>0.00%</td>
                                    <td>0.00%</td>
                                    <td>0.00%</td>
                                </tr>
                                <tr>
                                    <td>12/28/2010</td>
                                    <td>12/31/2010</td>
                                    <td>$0.4800</td>
                                    <td>13.43%</td>
                                    <td>44.90%</td>
                                    <td>41.07%</td>
                                    <td>0.00%</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2010_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2010 will be listed in Box 3 of your 1099-DIV. In addition, 23.51% of the dividends paid
                        qualify for the corporate dividends received deduction.</p>

                    <spacer></spacer>




                    <h4 className="underline">2009 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2009 distributions as
                        follows:</p>

                    <div id="tax2009_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/28/2009</td>
                                    <td>12/31/2009</td>
                                    <td>$0.1700</td>
                                    <td>33.74%</td>
                                    <td>66.26%</td>
                                    <td>0.00%</td>
                                    <td>0.00%</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2009_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2009 will be listed in Box 3 of your 1099-DIV.</p>

                    <spacer></spacer>


                    <h4 className="underline">2008 TAX INFORMATION</h4>
                    <p>The Denali Fund (NYSE: DNY) announced the final tax status for its 2008 distributions as
                        follows:</p>

                    <div id="tax2008_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>1/24/2008</td>
                                    <td>1/31/2008</td>
                                    <td>$0.1150</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>2/22/2008</td>
                                    <td>2/29/2008</td>
                                    <td>$0.1150</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>3/24/2008</td>
                                    <td>3/31/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>4/23/2008</td>
                                    <td>4/30/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>5/23/2008</td>
                                    <td>5/30/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>6/23/2008</td>
                                    <td>6/30/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>7/24/2008</td>
                                    <td>7/31/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>8/22/2008</td>
                                    <td>8/29/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>9/23/2008</td>
                                    <td>9/30/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>
                                <tr>
                                    <td>10/24/2008</td>
                                    <td>10/31/2008</td>
                                    <td>$0.1950</td>
                                    <td>1.11%</td>
                                    <td>20.47%</td>
                                    <td>59.62%</td>
                                    <td>18.80%</td>
                                </tr>

                            </tbody></table>

                    </div>

                    <div id="tax2008_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>

                    <p className="disclosure">The total distributions you received for calendar year-ended December 31,
                        2008 will be listed in Box 3 of your 1099-DIV.</p>
                </div>
            </div>

            <div className="container inner-content clearfix">

                <div className="col span_1_of_1 ">

                    <spacer></spacer>
                    <h3 className="underline">First Opportunity Fund Announces Tax Information</h3>

                    <spacer></spacer>

                    <p>Report of Organizational Actions Affecting Tax Basis</p>

                    <a href="/documents/stew/bouldercef-fofi-8937-20150320.pdf" target="_blank" className="docLink">
                        <span>FOFI 8937 Form - 2015 Merger</span>
                    </a>


                    <spacer></spacer>

                    <h4 className="underline">2015 TAX INFORMATION</h4>
                    <p>The First Opportunity Fund (NYSE: FOFI) announced the final tax status for its 2015 common
                        stock distributions as follows:</p>

                    <div id="tax2015_TableWrapper" className="tableHorizontalScroll">

                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>3/16/2015</td>
                                    <td>3/19/2015</td>
                                    <td>$0.08</td>
                                    <td>$0.08</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2015_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">44.93% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2014 TAX INFORMATION</h4>
                    <p>The First Opportunity Fund (NYSE: FOFI) announced the final tax status for its 2014 common
                        stock distributions as follows:</p>

                    <div id="tax2014_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/22/2014</td>
                                    <td>12/30/2014</td>
                                    <td>$0.15</td>
                                    <td>$0.14</td>
                                    <td>$0.02</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2014_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">88.22% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2013 TAX INFORMATION</h4>
                    <p>The First Opportunity Fund (NYSE: FOFI) announced the final tax status for its 2013 common
                        stock distributions as follows:</p>

                    <div id="tax2013_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/31/2013</td>
                                    <td>1/10/2014</td>
                                    <td>$0.26</td>
                                    <td>$0.17</td>
                                    <td>$0.09</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2013_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">58.71% of the dividends paid qualify for the corporate dividends received
                        deduction.</p>

                    <spacer></spacer>


                    <h4 className="underline">2012 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2012.</p>

                    <spacer></spacer>

                    <h4 className="underline">2011 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2011.</p>

                    <spacer></spacer>

                    <h4 className="underline">2010 TAX INFORMATION</h4>
                    <p>No common stock distributions were paid in 2010.</p>

                    <spacer></spacer>

                    <h4 className="underline">2009 TAX INFORMATION</h4>
                    <p>The First Opportunity Fund (NYSE: FOFI) announced the final tax status for its 2009 common
                        stock distributions as follows:</p>

                    <div id="tax2009_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/28/2009</td>
                                    <td>12/31/2009</td>
                                    <td>$0.03</td>
                                    <td>$0.03</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2009_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <spacer></spacer>

                    <h4 className="underline">2008 TAX INFORMATION</h4>
                    <p>The First Opportunity Fund (NYSE: FOFI) announced the final tax status for its 2008 common
                        stock distributions as follows:</p>

                    <div id="tax2008_TableWrapper" className="tableHorizontalScroll">
                        <table className="table--mod-1">
                            <tbody><tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2">Total Ordinary Dividends</th>
                                <th></th>
                                <th></th>
                            </tr>
                                <tr>
                                    <th>Record Date</th>
                                    <th>Payable Date</th>
                                    <th>Ammount per Share</th>
                                    <th>Qualified</th>
                                    <th>Non-Qualified</th>
                                    <th>Long-Term Capital Gains</th>
                                    <th>Return of Capital</th>
                                </tr>
                                <tr>
                                    <td>12/24/2008</td>
                                    <td>12/31/2008</td>
                                    <td>$0.13</td>
                                    <td>$0.13</td>
                                    <td>-</td>
                                    <td>$0.00 <sup>*</sup></td>
                                    <td>-</td>
                                </tr>
                            </tbody></table>

                    </div>

                    <div id="tax2008_TableIndicator" className="tableOverflowIndicator">&larr; Swipe to see additional
                        data &rarr;</div>
                    <p className="disclosure">*Less than $0.005 per share.</p>

                </div>
            </div>

            <div className="container clearfix">
                <div className="col span_1_of_1 ">
                    <p className="disclosure">None of the information on this website should be considered legal or tax
                        advice. The dividend tables provided here are for informational purposes and to assist you
                        in your tax planning. Please refer to your Form 1099-DIV and consult your legal and/or tax
                        advisor when preparing your tax return.</p>
                </div>
            </div>
        </>
    );
}

export default TaxInformation;
