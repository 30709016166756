import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RESOURCE_LITERATURE_DATA } from "../../utils/queries";
import config from "../../env/index.js";
import moment from "moment";
import SelectDropdown from "../../shared-components/SelectDropdown/index.js";

function Literature() {

  const [resourceEp, setResourceEp] = useState();
  const [fundList, SetFundList] = useState([])
  const [yearSelected, SetYearSelected] = useState(null)
  const [yearsOption, SetYearsOption] = useState([])
  const [fundsYearly, SetFundsYearly] = useState([])

  const literatureList = [
    { label: "Education", key: "education", list: ["education"] },
    { label: "Regulatory", key: "regulatory", list: ["regulatory"] },
    { label: "Related Documents", key: "related_documents", list: ["related_documents"] },
    { label: "Tax Information", key: "tax_information", list: ["tax_information"] },
  ]

  const { data, loading, error } = useQuery(RESOURCE_LITERATURE_DATA, {
    variables: {
      ticker: "STEW",
      documentTypes: [
        { "type": "distributions" },
        { "type": "education" },
        { "type": "fund_distributions" },
        { "type": "fundnews" },
        { "type": "regulatory" },
        { "type": "related_documents" },
        { "type": "tax_information" },
      ]
    }
  });

  const SEC_LIST = [
    { title: 'All SEC Filings', url: 'https://www.sec.gov/cgi-bin/browse-edgar?type=&dateb=&owner=include&count=40&action=getcompany&CIK=0000102426' },
    { title: 'Ownership Filings with the SEC', url: 'https://www.sec.gov/edgar/browse/?CIK=0000102426&owner=only' },
    { title: 'Quarterly Holdings (Form N-PORT)', url: 'https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=0000102426&type=NPORT&dateb=&owner=exclude&count=40' },
    { title: 'N-PX Filing', url: 'https://www.sec.gov/Archives/edgar/data/102426/000199937124013964/0001999371-24-013964-index.htm' },
  ]

  useEffect(() => {
    console.log("?>> data liter", data, config);
    let resourceEndpoint = config.graphQLEndpoint.match("staging") ? "https://staging-funds.paralel.com/download_resource/" : "https://api-funds.paralel.com/download_resource/";
    setResourceEp(resourceEndpoint);
  }, [data, config]);

  useEffect(() => {
    let tableList = []
    let yearsArray = []
    let documentList = Object.assign([], getArticles(['fundnews']))
    documentList?.sort((a, b) => {
      return moment(b.createdAt).diff(a.createdAt);
    })?.map((item) => {
      const year = moment(item?.createdAt).year();
      yearsArray.push(year);
      let obj = { ...item }
      tableList?.push(obj)
    })
    let uniqueList = [...new Set(yearsArray)]
    let yearList = []
    uniqueList?.map((year) => {
      yearList.push({
        label: year,
        value: year,
      })
    })
    if (yearList?.length > 0) {
      SetYearSelected(yearList[0])
    }
    SetYearsOption(yearList)
    SetFundList(tableList)
  }, [data, loading])

  useEffect(() => {
    if (yearSelected) {
      let yearList = Object.assign([], fundList)
      SetFundsYearly(yearList?.filter((x) => moment(x.createdAt)?.year() == yearSelected?.value))
    }
  }, [yearSelected, fundList])

  const callSelectDropdown = (item) => {
    SetYearSelected(item)
  }

  const getArticles = (documentList) => {
    let fundData = Object.assign([], data?.resources?.completeFundData)
    let filteredDocuments = fundData?.filter((x) => documentList?.includes(x?.type))?.map((item) => item?.funds).flat()
    filteredDocuments = filteredDocuments.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.fileName === value.fileName
      ))
    )
    return filteredDocuments?.sort((a, b) => moment(b.createdAt).diff(a.createdAt))
  }

  return (
    <div className="section__bg-2">
      <div className="container clearfix">
        <div className="col span_1_of_1 lg-full">
          <div id="literature" className="waypoint__subnav"></div>
          <section>
            <h3 className="underline">Literature</h3>
            <div className="main_content">
              <div id="fundnews" className="waypoint__subnav"></div>
              <div className="container clearfix">
                <div className="col span_1_of_1 filterDrop" style={{ marginBottom: "20px" }}>
                  <div className="col span_1_of_2">
                    <h4>Fund News</h4>
                  </div>
                  <div className="col span_1_of_2">
                    <div
                      style={{
                        marginTop: "0",
                        marginBottom: "0",
                        textAlign: "right",
                        transform: "translateY(8px)",
                      }}
                    >
                      <div className="dropdown_year_select_wrapper" style={{ width: '210px', marginLeft: 'auto' }}>
                        <SelectDropdown placeHolder={'SELECT A YEAR'} dropDownOptions={yearsOption} selectedValue={yearSelected} callSelectDropdown={callSelectDropdown} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {fundsYearly?.map(f =>
                    <div className="news-record" key={f.fileName} style={{ marginRight: "0" }}>
                      <div className="icon_box">
                        <a href={`${resourceEp}?id=${f.id}&ticker=${f.ticker}`} target="_blank" className="news__read-more">
                          <strong>{moment(f?.createdAt)?.format('MMM DD, YYYY')}</strong></a>
                        <h5 className="news__headline">{f.friendlyName}
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {literatureList?.map(({ label, key, list }) => {
                return (
                  getArticles([key])?.length > 0 &&
                  <>
                    <div id={key} className="waypoint__subnav"></div>
                    <div className="container clearfix">
                      <div className="col span_1_of_1 filterDrop" style={{ marginBottom: "20px" }}>
                        <h4>{label}</h4>
                      </div>
                      {getArticles(list)?.map(f =>
                        <a
                          href={`${resourceEp}?id=${f.id}&ticker=${f.ticker}`}
                          target="_blank"
                          key={f.fileName}
                          className="docLink"
                        >
                          <span>{f.friendlyName}</span>
                        </a>)}
                      {key == 'tax_information' ?
                      <>
                        <a
                          href={`/stew/tax-documents`}
                          key={"Other Tax Information"}
                          className="docLink"
                        >
                          <span>Other Tax Information</span>
                        </a>                     
                        </>
                        : null}
                    </div>
                  </>
                )
              })}
              <div id="sec_filings" className="waypoint__subnav"></div>
              <div className="container clearfix">
                <div className="col span_1_of_1 filterDrop" style={{ marginBottom: "20px" }}>
                  <h4>SEC Filings</h4>
                </div>
                {SEC_LIST?.map(f =>
                  <a
                    href={`${f.url}`}
                    target="_blank"
                    key={f.title}
                    className="docLink"
                  >
                    <span>{f.title}</span>
                  </a>)}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Literature;
