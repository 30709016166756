import React, { useState } from "react";
import { stewLink } from "../utils/constants";
import SRHFunds from '../assets/images/SRH_FUNDS_Logo.svg';

function Header() {
  const [fundAction, setFundAction] = useState(false);

  return (
    <header id="mainHeader">
      <div className="full-width" id="navamongus">
        {/* <div className="meta-nav__wrapper">
				<div className="container">
					<ul className="meta-nav">
						<li className="meta-nav__item"><a href="/" className="meta-nav__link">Home</a></li>
					</ul>
				</div>
			</div> */}
        {/* <mobileNavButton></mobileNavButton> */}
        <div className="nav_container">
          <a href="/" className="site-header-logo">
            <img
              src={SRHFunds}
              alt="logo"
            />
          </a>
          <div id="main-nav" className="srh-funds-nav-header">
            <div className="nav-item">
              <div onClick={() => setFundAction(!fundAction)}>The Funds</div>
              {fundAction && (
                <div className="wrapper-component">
                  <div
                    className="nav-items"
                    onClick={() => setFundAction(!fundAction)}
                  >
                    <a href="/stew">STEW – SRH Total Return Fund</a>
                  </div>
                  <div
                    className="nav-items"
                    onClick={() => setFundAction(!fundAction)}
                  >
                    <a href="/srhq">SRHQ – SRH U.S. Quality ETF</a>
                  </div>
                  <div
                    className="nav-items"
                    onClick={() => setFundAction(!fundAction)}
                  >
                    <a href="/srhr">SRHR - SRH REIT COVERED CALL ETF</a>
                  </div>
                </div>
              )}
            </div>
            <div className="nav-item">
              <a href="/about-us">About Us</a>
            </div>
            {/* <div className="nav-item">
              <a href="/articles">Articles</a>
            </div> */}
          </div>
          <div className="fund-picker"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
