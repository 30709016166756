import { useState, useRef, useEffect} from "react";
import Fundheader from "../components/fundheader";
import LinkedIn from '../assets/images/linkedin.svg';
import { CONTACT_US } from "../utils/queries";
import { useMutation } from "@apollo/client";
import Spinner from '../assets/images/spinner.gif';
import ReCAPTCHA from 'react-google-recaptcha';


function Contact() {
  const [isLoading, setIsLoading] = useState(false)
  const [contactUsAPI] = useMutation(CONTACT_US);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [notify, setNotify] = useState(null);
  const [notifyClr, setNotifyClr] = useState(null);
  const recaptcha = useRef();

  useEffect(() => {
    if (firstName?.trim() !== '' && lastName?.trim() !== '' && email?.trim() !== '' && phoneNum?.trim() !== '') {
        setDisabled(false)
    } else {
        setDisabled(true)
    }
}, [firstName, lastName, email, phoneNum])


  const openTab = (link) => {
    window.open(link, '_blank')
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setNotify('Please verify the reCAPTCHA!');
      setNotifyClr('tomato');
    } else {
      var form = document.getElementById('formContact');
      let formData = Object.fromEntries(new FormData(form).entries())
      if (formData['firstname']?.trim() !== '' && formData['lastname']?.trim() !== '' && formData['email']?.trim() !== '' && formData['phone']?.trim() !== '') {
        setIsLoading(true)
        let emailBody = `
        Hi Admin, 
      
        The following user has shown interest in SRH Funds
      
        First Name: ${formData['firstname']}, 
        Last Name: ${formData['lastname']}, 
        Email: ${formData['email']},
        Phone: ${formData['phone']},
        Street: ${formData['street']}, 
        City: ${formData['City']}, 
        State: ${formData['State']}, 
        Zip code: ${formData['Zip']}, 
        Message: ${formData['Message']}, 
        
        Regards,
        Paralel Team
      `;
        let toEmail = "clientinquirysupport@paralel.com";
        e.preventDefault();
        let response = await contactUsAPI({
          variables:
          {
            emailBody: emailBody, emails: [toEmail], emailSubject: "Contact from website."
          },
          fetchPolicy: "no-cache"
        });
        if (response?.data?.sendContactUsMail?.ok) {
          setIsLoading(false)
          setNotify('Email Sent!');
          setNotifyClr('forestgreen');
          setTimeout(() => {
            form.reset();
            setNotify(null);
            recaptcha.current.reset();
          }, 2000)
        } else {
          setIsLoading(false);
          setNotify('Some Error Occured!');
          setNotifyClr('tomato');
          setTimeout(function () {
            setNotify(null);
          }, 3000);
        }
      }
    }
    return false;
  }

  return (
    <>
      <Fundheader></Fundheader>
      {isLoading ?
        <div id="loading">
          <img
            id="loading-image"
            src={Spinner}
            alt="Loading..."
          />
        </div> : null}
      <main id="mainContent">
        <div className="main_content">
          <div className="hero-short hero-short__noSubnav">
            <div className="container">
              <div className="col span_6_of_10 ">
                <span className="hero-short__title">
                  <strong>Contact Us</strong>
                </span>
              </div>
            </div>
            <bgImage className="bgImage__img-5"></bgImage>
          </div>
          <div className="container inner-content clearfix">
            <div className="col span_1_of_2">
              <div id="formsub" className="formSub">
                <form id="formContact"className="contactform form-horizontal">
                  <fieldset>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="firstname"
                          required
                          placeholder="* First Name"
                          size="42"
                          className
                          id="formContact-element-1"
                          value={firstName} 
                          onChange={(e) => setFirstName(e.target?.value)}
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="lastname"
                          required
                          placeholder="* Last Name"
                          size="42"
                          className
                          id="formContact-element-2"
                          value={lastName} 
                          onChange={(e) => setLastName(e.target?.value)}
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="email"
                          name="email"
                          required
                          placeholder="* Email"
                          size="42"
                          className
                          id="formContact-element-3"
                          value={email} 
                          onChange={(e) => setEmail(e.target?.value)}
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="tel"
                          name="phone"
                          required
                          placeholder="* Phone"
                          size="42"
                          className
                          id="formContact-element-4"
                          value={phoneNum} 
                          onChange={(e) => setPhoneNum(e.target?.value)}
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="street"
                          placeholder="Street"
                          size="42"
                          className
                          id="formContact-element-5"
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="City"
                          size="42"
                          placeholder="City"
                          id="formContact-element-6"
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <select name="State" id="formContact-element-7">
                          <option value="">--Select State--</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="Zip"
                          size="42"
                          placeholder="Zip"
                          id="formContact-element-8"
                        />
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <textarea
                          type="text"
                          name="Message"
                          size="42"
                          style={{ resize: "none", width: "97%" }}
                          placeholder="Enter your message"
                          id=""
                        ></textarea>
                      </div>
                      <div className="formLabel">* Required Fields</div>
                      <br></br>
                      <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={()=>{setNotify(null)}}/>
                      <div className="form-actions">
                        <input
                          style={{ color: disabled ? '#d5d0c9' : '#6b9dc1'}}
                          type="submit"
                          value="Submit"
                          className="form_submit contact-btn"
                          onClick={(e) => !disabled && submitForm(e)}
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="col span_1_of_2 contact-us">
              <p>
                Paralel Advisors LLC <br></br>
                1700 Broadway, Suite 1850<br></br>
                Denver, CO 80290<br></br>
                303-226-4150<br /><br/>
                For inquiries regarding the SRH Total Return Fund, Inc., <br /> please contact us at 877-561-7914.<br /><br />
                For inquiries regarding our ETFs, please contact us at 877-524-9155.<br></br>
              </p>
            </div>
          </div>
        </div>
        <div className="container inner-content clearfix">
          <div className='footer-logo'>
            <img src={LinkedIn} onClick={() => openTab(`https://www.linkedin.com/company/srh-funds/`)} />
          </div>
        </div>
        <div id="contentEndTrigger"></div>
        {notify && <p id="pulsate" style={{ textAlign: "center", fontSize: "22px", color: notifyClr, position: "fixed", bottom: "7%", width: "100%" }}>
          {notify}
        </p>}
      </main>
    </>
  );
}

export default Contact;
