import React, { useCallback, useState, useEffect } from "react";
import { PieChart, Pie, Cell, Sector } from "recharts";
import { CSVLink } from "react-csv";
import { isValidData, numberWithCommas, toDecimal } from "../utils";
import moment from "moment";

function Holdings(props) {
  const { fullHoldingsData = [], sectorAllocation = [], ticker = '', sectorDate = null } = props
  const [activeIndex, setActiveIndex] = useState(0);
  const [sectorData, setSectorData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [holdingsData, setHoldingsData] = useState([]);
  const [date, setAsOfDate] = useState(null);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  let parse = (s) =>
    typeof s === "string" ? parseInt(s.replace(/,/g, "")) : s;

  useEffect(() => {
    if (sectorAllocation?.length > 0) {
      let tableList = Object.assign([], sectorAllocation)
      let list = []
      tableList.sort((a, b) => parse(b['v']) - parse(a['v']))?.map((item, index) => {
        let obj = {
          name: item['n'],
          value: item['v'],
        }
        list?.push(obj)
      })
      setSectorData(list)
    }
    // else {
    //   setSectorData([])
    // }
  }, [sectorAllocation])

  useEffect(() => {
    let list = []
    if (fullHoldingsData?.length > 0 && ticker == 'STEW') {
      setAsOfDate(fullHoldingsData[0]?.asOfDate)
      let tableList = Object.assign([], fullHoldingsData)
      let total = fullHoldingsData.reduce((total, obj) => parseFloat(obj.percentMarketValue * 100) + total, 0)
      setTotalPercentage(total)
      tableList.sort((a, b) => parseFloat(b.percentMarketValue) - parseFloat(a.percentMarketValue))?.map((item, index) => {
        let obj = {
          id: index + 1,
          security: item['description'],
          marketValue: isValidData(item['marketValue'], "market"),
          weight: isValidData(item['percentMarketValue'] * 100, "temp")
        }
        list.push(obj)
      })
    } else if (fullHoldingsData?.length > 0) {
      setAsOfDate(fullHoldingsData[0]?.holdingDate)
      let tableList = Object.assign([], fullHoldingsData)
      let total = fullHoldingsData.reduce((total, obj) => parseFloat(obj.weight) + total, 0)
      setTotalPercentage(total)
      tableList.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight))?.map((item, index) => {
        let obj = {
          id: index + 1,
          security: item['name'],
          ticker: item['hTicker'],
          securityId: item['secNumFull'],
          shares: numberWithCommas(item['sharesPar']),
          marketValue: isValidData(item['marketValue'], "market"),
          weight: isValidData(item['weight'], "temp")
        }
        list.push(obj)
      })
    }
    let filterRecords = list?.filter((x) => x.id <= 10)
    setHoldingsData(filterRecords)
    setCompleteData(list)
    setShowAll(false)
  }, [fullHoldingsData])

  const showTopHoldings = () => {
    let list = Object.assign([], completeData)
    let filterRecords = list?.filter((x) => x.id <= 10)
    setShowAll(false)
    setHoldingsData(filterRecords)
  }

  const showAllRecords = () => {
    let list = Object.assign([], completeData)
    setShowAll(true)
    setHoldingsData(list)
  }

  const COLORS = [
    "#dea50b",
    "#067074",
    "#533869",
    "#93B309",
    "#2B4445",
    "#a62135",
    "#674300",
    "#2B8405",
    "#406774",
    "#3f41e5",
    "purple",
    "#e53f3f",
    "#15c5c9",
    "#a67cbf",
    "#d6e808",
    "#e84f76",
    "#2a7fe9",
    "#0adfb3",
    "#de0aad",
    "#00007F",
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          width={20}
        >
          {payload.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {percent !== "" && percent > 0 && !isNaN(percent)
            ? `${(percent * 100).toFixed(2)}%`
            : "-"}
        </text>
      </g>
    );
  };

  const getChartData = (data) => {
    let record = Object.assign([], data)
    return record?.length > 0 ? record?.filter((x) => (x?.value !== "" && !isNaN(x?.value) && x?.value >= 0)) : [];
  }

  const precisionRound = (number, precision) => {
    var factor = Math.pow(10, precision);
    var roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber.toFixed(precision);
  };

  const getColumnName = (key) => {
    if (key == 'id') {
      return ''
    } else if (key == 'security') {
      return 'Security'
    } else if (key == 'ticker') {
      return 'Ticker'
    } else if (key == 'securityId') {
      return 'Security ID'
    } else if (key == 'shares') {
      return 'Number of Shares'
    } else if (key == 'marketValue') {
      return 'Market Value'
    } else if (key == 'weight') {
      return 'Weight'
    }
  }

  const getExportData = (data) => {
    let totalData = []
    if (ticker == "STEW") {
      totalData = [{
        id: '',
        security: '',
        marketValue: 'Total',
        weight: totalPercentage ? totalPercentage?.toFixed(2) + '%' : '-'
      }]
    } else {
      totalData = [{
        id: '',
        security: '',
        ticker: '',
        securityId: '',
        shares: '',
        marketValue: 'Total',
        weight: totalPercentage ? totalPercentage?.toFixed(2) + '%' : '-'
      }]
    }
    let tableData = []
    data?.concat(totalData)?.map((item, idx) => {
      let obj = {}
      Object.keys(item)?.map((data) => {
        obj[getColumnName(data)] = item[data]
      })
      tableData.push(obj)
    })
    return tableData
  }

  return (
    <>
      <div id="holdings" className="waypoint__subnav"></div>
      <div className="section__bg-2 hero_waypoint">
        <div className="container clearfix inner-content">
          <div className="col span_1_of_1">
            <h3 className="underline">Holdings</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div>
            <h5 className="underline">
              Top Holdings
              {date &&
                <span className="tilldate">{`As of ${date ? moment(date)?.format('MM/DD/YYYY') : ''}`}</span>
              }
              {completeData?.length > 0 &&
                <CSVLink data={getExportData(completeData)} filename={`Holdings_${moment(date)?.format('MM.DD.YYYY')}.csv`}>
                  <button style={{ float: "right", border: "1px solid", borderRadius: "5px", color: "#808285", fontSize: "1.2rem", padding: "3px 5px 4px", marginTop: "-5px" }}>Download Full Holdings</button>
                </CSVLink>
              }
            </h5>
            <div className="chartContainer">
              <table id="holdings_export_table" className="table--mod-3">
                <thead>
                  <tr>
                    <th></th>
                    <th>Security</th>
                    {ticker !== "STEW" ?
                      <>
                        <th style={{ textAlign: "center" }}>Ticker</th>
                        <th style={{ textAlign: "center" }}>Security ID</th>
                        <th style={{ textAlign: "center" }}>Number of Shares</th>
                      </>
                      : null}
                    <th style={{ textAlign: "center" }}>Market Value</th>
                    <th style={{ textAlign: "center" }}>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {holdingsData?.map((data) => {
                    return (
                      <tr>
                        <td>{data?.id}</td>
                        <td>{data?.security}</td>
                        {ticker !== "STEW" ?
                          <>
                            <td>{data?.ticker}</td>
                            <td style={{ textAlign: "center" }}>{data?.securityId}</td>
                            <td style={{ textAlign: "center" }}>{data?.shares}</td>
                          </> : null
                        }
                        <td style={{ textAlign: "center" }}>{data?.marketValue}</td>
                        <td>{data?.weight}</td>
                      </tr>
                    )
                  })}
                  {showAll ?
                    ticker == "STEW" ?
                      <tr><td></td><td></td><td style={{ textAlign: "center" }}><b>Total</b></td><td>{totalPercentage ? totalPercentage?.toFixed(2) + '%' : '-'}</td></tr> :
                      <tr><td></td><td></td><td></td><td></td><td></td><td style={{ textAlign: "center" }}><b>Total</b></td><td>{totalPercentage ? totalPercentage?.toFixed(2) + '%' : '-'}</td></tr> :
                    null
                  }
                </tbody>
              </table>
              {showAll ?
                <button className="holdings" onClick={() => showTopHoldings()}>Show Top 10</button> :
                <button className="holdings" onClick={() => showAllRecords()}>Show All</button>
              }
              <p className="holdings-change">Holdings are subject to change.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <h5 className="underline">
              Sector Breakdown
              {(sectorDate || date) &&
                <span className="tilldate">{`As of ${(sectorDate || date) ? moment((sectorDate || date))?.format('MM/DD/YYYY') : ''}`}</span>
              }
            </h5>
          </div>
          <div className="sector-allocation-chart">
            <div style={{ padding: "5px", fontSize: "10px" }}>
              <PieChart width={700} height={500}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={getChartData(sectorData)}
                  cx={350}
                  cy={200}
                  innerRadius={80}
                  outerRadius={120}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {getChartData(sectorData).map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="sector-allocation-legends">
              <table class="table--mod-7">
                {sectorData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                {sectorData.map((entry, index) => (
                  <tr>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td>{entry.name}</td>
                    <td>
                      {entry.value !== "" &&
                        // entry.value > 0 &&
                        !isNaN(entry.value)
                        ? `${parseFloat(precisionRound(entry.value, 2)).toFixed(
                          2
                        )}%`
                        : "-"}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Holdings;
