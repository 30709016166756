import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";

import { baseUrlLink } from "../utils/constants";

function Premium(props) {
  const { fund, startDate, chartData, countData } = props
  const LAST_YEAR = moment(new Date())?.subtract(1, 'y')?.year()
  const CURRENT_YEAR = moment(new Date())?.year()
  const [quarterData, setQuarterData] = useState([]);
  const [premiumData, setPremiumData] = useState([]);
  const [xValues, setXValues] = useState([]);


    /*
     [
      {name: "Premium", yearly, q1, q2, q3, q4},
      {name: "Par", yearly, q1, q2, q3, q4},
      {name: "Discount", yearly, q1, q2, q3, q4},
     ]
      */

  useEffect(() => {
    // if (fund == "SRHR") {
      let final = []
      if (countData?.length > 0) {

        let premium = countData?.filter((x) => x?.year == CURRENT_YEAR)?.map(c => c.premium);
        let par = countData?.filter((x) => x?.year == CURRENT_YEAR)?.map(c => c.par);
        let discount = countData?.filter((x) => x?.year == CURRENT_YEAR)?.map(c => c.discount);

        let yearly_premium = countData?.filter((x) => x?.year == LAST_YEAR)?.map(c => c.premium);
        let yearly_par = countData?.filter((x) => x?.year == LAST_YEAR)?.map(c => c.par);
        let yearly_discount = countData?.filter((x) => x?.year == LAST_YEAR)?.map(c => c.discount);

        final = [
          {
            name: "Premium",
            last_year: yearly_premium?.reduce((a, b) => a + b) || 0,
            yearly: premium?.reduce((a, b) => a + b) || 0,
            q1: premium[0] || 0,
            q2: premium[1] || 0,
            q3: premium[2] || 0,
            q4: premium[3] || 0,
          },
          {
            name: "Par",
            last_year: yearly_par?.reduce((a, b) => a + b) || 0,
            yearly: par?.reduce((a, b) => a + b) || 0,
            q1: par[0] || 0,
            q2: par[1] || 0,
            q3: par[2] || 0,
            q4: par[3] || 0,
          },
          {
            name: "Discount",
            last_year: yearly_discount?.reduce((a, b) => a + b) || 0,
            yearly: discount?.reduce((a, b) => a + b) || 0,
            q1: discount[0] || 0,
            q2: discount[1] || 0,
            q3: discount[2] || 0,
            q4: discount[3] || 0,
          },
        ]
        console.log("?>> countData", countData, premium, par, discount, final, premiumData);

        // countData?.map((item, idx) => {
        //   let obj = {
        //     name: item?.header,
        //     yearly: item?.Year,
        //     q1: item?.Q1,
        //     q2: item?.Q2,
        //     q3: item?.Q3,
        //     q4: item?.Q4,
        //   }
        //   final.push(obj)
        // })
      }
      setQuarterData(final);
    // } else {
    //   const url = baseUrlLink + "/read/premium-quarter?location=etf";

    //   const fetchData = async () => {
    //     try {
    //       const quer = await fetch(url);
    //       const response = await quer.json();
    //       if (response.flag) {
    //         let data = response.data;
    //         let lastPremium = 0;
    //         let lastDiscount = 0;
    //         let lastNav = 0;
    //         let yearlyPremium = 0;
    //         let yearlyDiscount = 0;
    //         let yearlyNav = 0;

    //         let q1Premium = 0;
    //         let q1Discount = 0;
    //         let q2Premium = 0;
    //         let q2Discount = 0;

    //         let q3Premium = 0;
    //         let q3Discount = 0;

    //         let q4Premium = 0;
    //         let q4Discount = 0;
    //         let q1Nav = 0;
    //         let q2Nav = 0;
    //         let q4Nav = 0;
    //         let q3Nav = 0;

    //         if (data && data.length > 0) {
    //           data?.filter((x) => (x.year == CURRENT_YEAR || x.year == LAST_YEAR)).map((x, i) => {
    //             if (x.year == CURRENT_YEAR) {
    //               yearlyPremium = parseInt(yearlyPremium) + parseInt(x.premium);
    //               yearlyDiscount = parseInt(yearlyDiscount) + parseInt(x.discount);
    //               yearlyNav = parseInt(yearlyNav) + parseInt(x.nav);
    //             } else if (x.year == LAST_YEAR) {
    //               lastPremium = parseInt(lastPremium) + parseInt(x.premium);
    //               lastDiscount = parseInt(lastDiscount) + parseInt(x.discount);
    //               lastNav = parseInt(lastNav) + parseInt(x.nav);
    //             }
    //             if (x.quarter == "Q1" && x.year == CURRENT_YEAR) {
    //               q1Premium = parseInt(q1Premium) + parseInt(x.premium);
    //               q1Discount = parseInt(q1Discount) + parseInt(x.discount);
    //               q1Nav = parseInt(q1Nav) + parseInt(x.nav);
    //             } else if (x.quarter == "Q2" && x.year == CURRENT_YEAR) {
    //               q2Premium = parseInt(q2Premium) + parseInt(x.premium);
    //               q2Discount = parseInt(q2Discount) + parseInt(x.discount);
    //               q2Nav = parseInt(q2Nav) + parseInt(x.nav);
    //             } else if (x.quarter == "Q3" && x.year == CURRENT_YEAR) {
    //               q3Premium = parseInt(q3Premium) + parseInt(x.premium);
    //               q3Discount = parseInt(q3Discount) + parseInt(x.discount);
    //               q3Nav = parseInt(q3Nav) + parseInt(x.nav);
    //             } else if (x.quarter == "Q4" && x.year == CURRENT_YEAR) {
    //               q4Premium = parseInt(q4Premium) + parseInt(x.premium);
    //               q4Discount = parseInt(q4Discount) + parseInt(x.discount);
    //               q4Nav = parseInt(q4Nav) + parseInt(x.nav);
    //             }
    //           });
    //         }
    //         let final = [
    //           {
    //             name: "Premium",
    //             lastYear: lastPremium,
    //             yearly: yearlyPremium,
    //             q1: q1Premium,
    //             q2: q2Premium,
    //             q3: q3Premium,
    //             q4: q4Premium,
    //           },
    //           {
    //             name: "Par",
    //             lastYear: lastNav,
    //             yearly: yearlyNav,
    //             q1: q1Nav,
    //             q2: q2Nav,
    //             q3: q3Nav,
    //             q4: q4Nav,
    //           },
    //           {
    //             name: "Discount",
    //             lastYear: lastDiscount,
    //             yearly: yearlyDiscount,
    //             q1: q1Discount,
    //             q2: q2Discount,
    //             q3: q3Discount,
    //             q4: q4Discount,
    //           },
    //         ];
    //         setQuarterData(final);
    //       }
    //     } catch (error) {
    //       console.log("error", error);
    //     }
    //   };

    //   fetchData();
    // }
  }, [countData, premiumData]);

  useEffect(() => {
    // if (fund == "SRHR") {
      let graphData = []
      if (chartData?.length > 0) {
        chartData?.map((data, idx) => {
          let premiumStartDate = moment(startDate).subtract(1, 'd').format('YYYY-MM-DD');
          if (moment(data?.period)?.isBetween(premiumStartDate)) {
            graphData.push({
              name: moment(data.period).format("MMM Do, YYYY"), //MMM Do,YYYY
              value: data.premium?.toFixed(2),
            });
          }
        })
      }
      setPremiumData(graphData)
    // } else {
    //   const graphurl = baseUrlLink + "/read/premium?location=etf";

    //   const fetchGraphData = async () => {
    //     let graphData = [];
    //     try {
    //       const quer = await fetch(graphurl);
    //       const response = await quer.json();
    //       if (response.flag) {
    //         let dataList = response.data?.filter((x) => ((new Date(x.date)?.getFullYear() == CURRENT_YEAR) || (new Date(x.date)?.getFullYear() == LAST_YEAR)));
    //         dataList.sort((a, b) =>
    //           moment(new Date(a.date)) > moment(new Date(b.date)) ? 1 : -1
    //         );
    //         dataList.map((x) => {
    //           graphData.push({
    //             name: fund == 'SRHR' ? moment(x.date).format("MMM Do, YYYY") : moment(new Date(x.date)).format("MMM Do, YYYY"),
    //             value: x.discount,
    //           });
    //         });

    //         setPremiumData(graphData);
    //       }
    //     } catch (error) {
    //       console.log("error", error);
    //     }
    //   };

    //   fetchGraphData();
    // }
  }, [chartData, startDate]);

  return (
    <Modal
      className="premium"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Premium / Discount</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="info">
          The following table and line graph are provided to show the frequency
          at which the closing price of the Fund was at a premium (above) or
          discount (below) to the Fund’s daily net asset value (“NAV”). The
          table and line graph represent past performance and cannot be used to
          predict future results. Shareholders may pay more than NAV when buying
          Fund shares and receive less than NAV when those shares are sold
          because shares are bought and sold at current.
        </p>
        <div className="tableHorizontalScroll">
          <h5>Number of Days At</h5>
          <table class="table--mod-2">
            <tbody>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>{LAST_YEAR}</th>
                <th style={{ textAlign: "center" }}>{CURRENT_YEAR}</th>
                <th style={{ textAlign: "center" }}>Q1</th>
                {quarterData?.reduce((total, obj) => obj.q2 + total, 0) > 0 &&
                  <th style={{ textAlign: "center" }}>Q2</th>
                }
                {quarterData?.reduce((total, obj) => obj.q3 + total, 0) > 0 &&
                  <th style={{ textAlign: "center" }}>Q3</th>
                }
                {quarterData?.reduce((total, obj) => obj.q4 + total, 0) > 0 &&
                  <th style={{ textAlign: "center" }}>Q4</th>
                }
              </tr>
              {quarterData.map((x, index) => (
                <tr>
                  <td>{x.name}</td>
                  <td>{x.last_year}</td>
                  <td>{x.yearly}</td>
                  <td>{x.q1}</td>
                  {quarterData?.reduce((total, obj) => obj.q2 + total, 0) > 0 &&
                    <td>{x.q2}</td>
                  }
                  {quarterData?.reduce((total, obj) => obj.q3 + total, 0) > 0 &&
                    <td>{x.q3}</td>
                  }
                  {quarterData?.reduce((total, obj) => obj.q4 + total, 0) > 0 &&
                    <td>{x.q4}</td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ margin: "10px 0px" }}>
            {/* <h5>% Previous Year to Date</h5> */}
            <LineChart
              width={700}
              height={500}
              data={premiumData}
              isAnimationActive={premiumData?.length > 0 ? true : false}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 25,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                type="category"
                label={{
                  value: "Date",
                  offset: 0,
                  position: "bottom",
                }}
              />
              <YAxis
                // domain={[-0.2, "0.20"]}
                allowDataOverflow={true}
                // ticks={[100, 120, 140, 160, 180]}
                // domain={[80, 200]}
                domain={fund == 'SRHR' ? [-3, 3] : [-0.3, 0.3]}
                ticks={fund == 'SRHR' ? [-2, -1, 0, 1, 2] : [-0.2, -0.15, -0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2]}
                label={{
                  value: "Premium or Discount (%)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#59981A" />
            </LineChart>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default Premium;
