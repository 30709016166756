import LinkedIn from '../assets/images/linkedin.svg'

function Homefooter(props) {

  const openTab = (link) => {
    window.open(link, '_blank')
  }

  return (
    <footer id="mainFooter">
      <div className="container">
        <div className="disclosure">
          <p>
            An investment in the Funds involves risk, including loss of
            principal. There is no assurance the Funds will meet their
            investment objective.{" "}
          </p>
          <p>Past performance is not indicative of future results.</p>
          <p>Not FDIC Insured | May Lose Value | No Bank Guarantee.</p>
          <p>
            NOTHING IN THIS WEBSITE IS INTENDED TO BE, AND YOU SHOULD NOT
            CONSIDER ANYTHING IN THIS WEBSITE TO BE, INVESTMENT, ACCOUNTING, TAX
            OR LEGAL ADVICE. IF YOU WOULD LIKE INVESTMENT, ACCOUNTING, TAX OR
            LEGAL ADVICE, YOU SHOULD CONSULT WITH YOUR OWN FINANCIAL ADVISORS,
            ACCOUNTANTS, OR ATTORNEYS REGARDING YOUR INDIVIDUAL CIRCUMSTANCES
            AND NEEDS.
          </p>
          <p>
            Information on this website is provided "AS IS" without warranty of
            any kind, either express or implied, including, but not limited to,
            the implied warranties of merchantability, fitness for a particular
            purpose, or non-infringement. The data set forth on this website is
            for informational purposes only, and no warranty is made that the
            information is error-free.
          </p>
          <p>
            Paralel Distributors LLC is the distributor of the SRH U.S. Quality
            ETF. Paralel Distributors LLC and Paralel Advisors LLC are
            wholly-owned subsidiaries of Paralel Technologies LLC. SCLT
            Holdings, LLC holds a non-controlling investment in Paralel
            Technologies LLC and is under common control with Rocky Mountain
            Advisers, LLC. Vident Investment Advisory, LLC is not affiliated
            with Paralel Technologies LLC or Rocky Mountain Advisers, LLC.
          </p>
          <p>©2022 - Paralel Advisors LLC - All rights reserved.</p>
        </div>
        <div className='footer-logo'>
          <img src={LinkedIn} onClick={() => openTab(`https://www.linkedin.com/company/srh-funds/`)} />
        </div>
      </div>
    </footer>
  );
}

export default Homefooter;
