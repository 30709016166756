import moment from "moment";
import { isValidDate, numberWithCommas, toDecimal } from "../../utils";

function Overview(props) {
  let { dailyData, fundData, fullHoldingsData = [] } = props;

  console.log("?>> dailyData", dailyData, fundData);

  return (
    <>
      <div className="section__bg-2 hero_waypoint">
        <div className="container clearfix inner-content">
          <div className="col span_1_of_1">
            <div id="overview" className="waypoint__subnav"></div>
            <h3 className="underline">Overview</h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col span_1_of_2">
            <p>
              SRH Total Return Fund, Inc. (the "Fund") is a non-diversified closed-end fund with a total return investment objective. To achieve its objective, the Fund utilizes a bottom-up, value-driven investment process to identify securities of good quality businesses trading below estimated intrinsic value. The intent of this process is to identify investment opportunities that will provide attractive returns over a long holding period. By doing this, the Fund seeks to keep portfolio turnover low, which the sub-adviser believes helps to minimize associated trading costs. The Fund maintains a high level of investment flexibility and has the ability to invest in equity and fixed income securities of domestic and international companies without limitation to a company's market capitalization.
            </p>
            <p>
              The Fund is managed by its investment adviser, Paralel Advisors LLC. Rocky Mountain Advisers, LLC serves as the Fund's sub-adviser. Joel Looney, CFP and Jacob Hemmer, CFA of the sub-adviser serve as the Fund's portfolio managers and, with assistance from other advisory personnel, are jointly responsible for the day-to-day management of the Fund's assets.
            </p>
            <p>
              The Fund is publicly traded on the New York Stock Exchange under the ticker symbol "STEW".
            </p>
          </div>
          <div className="col span_1_of_2">
            <h4 className="nouppercase underline h4_mod1">Note to Stockholders on Concentration of Investments</h4>
            <p>
              The Fund's management feels that it is important that stockholders be aware that the Fund is highly concentrated in a small number of positions. Concentrating investments in a fewer number of securities may involve a degree of risk that is greater than a fund which has less concentrated investments spread out over a greater number of securities. In particular, the Fund is highly concentrated in Berkshire Hathaway Inc., which, in addition to other business risks, is dependent on Warren Buffett for major investment decisions and all major capital allocation decisions. If Mr. Buffett were no longer able to fulfill his responsibilities to Berkshire Hathaway Inc., the effect on the value of the Fund's position in Berkshire Hathaway Inc. could be materially negative.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <h5 className="underline">Fund Details</h5>
        <div className="row">
          <div className="col span_1_of_2">
            <section>
              <div style={{ display: "flex" }}>
                <span className="tilldate portfolio-asofdate-center">{dailyData?.asOfDate ? `As of ${moment(isValidDate(dailyData?.asOfDate))?.format('MM/DD/YYYY')}` : ''}</span>
              </div>
              <table className="table--mod-6">
                <tr>
                  <td>Ticker Symbol</td>
                  <td>STEW</td>
                </tr>
                <tr>
                  <td>CUSIP</td>
                  <td>101507101</td>
                </tr>
                <tr>
                  <td>Inception Date <sup>[1]</sup></td>
                  <td>01/31/2002</td>
                </tr>
                <tr>
                  <td>Expense Ratio <sup>[2]</sup></td>
                  <td>1.60%</td>
                </tr>
                <tr>
                  <td>Distribution Frequency</td>
                  <td>Quarterly</td>
                </tr>
                <tr>
                  <td>Exchange</td>
                  <td>NYSE</td>
                </tr>
              </table>
            </section>
            <p className="sub-disclosure"><sup>1</sup> Inception Date reflected above is when affiliates of the sub-adviser became investment advisers to the Fund.</p>
            <p className="sub-disclosure"><sup>2</sup> Expense Ratio reflected above is current as of the latest annual shareholder report.</p>
          </div>
          <div className="col span_1_of_2">
            <section>
              <div style={{ display: "flex" }}>
                <span className="tilldate portfolio-asofdate-center">{dailyData?.asOfDate ? `As of ${moment(isValidDate(dailyData?.asOfDate))?.format('MM/DD/YYYY')}` : ''}</span>
              </div>
              <table className="table--mod-6">
                <tr>
                  <td>Total Net Assets</td>
                  <td>{dailyData?.totalNetAssets ? '$' + numberWithCommas(dailyData?.totalNetAssets) : '-'}</td>
                </tr>
                <tr>
                  <td>Total Managed Assets</td>
                  <td>{dailyData?.totalManagedAssets ? '$' + numberWithCommas(dailyData?.totalManagedAssets) : '-'}</td>
                </tr>
                <tr>
                  <td>Number of Holdings</td>
                  <td>{fullHoldingsData?.length ? fullHoldingsData?.length : '-'}</td>
                </tr>
                <tr>
                  <td>Premium / Discount</td>
                  <td>{dailyData?.premiumDiscount ? toDecimal(dailyData?.premiumDiscount * 100, 2) + '%' : '0'}</td>
                </tr>
              </table>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
