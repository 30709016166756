import moment from "moment";
import Arrow from '../../assets/images/proceed.svg'
import { isValidDate } from "../../utils";

function Overview(props) {
    let {dailyData, fundData} = props;

    console.log("?>> dailyData", dailyData, fundData);

  return (
    <>
      <div className="section__bg-2 hero_waypoint">
        <div className="container clearfix inner-content">
          <div className="col span_1_of_1">
            <div id="overview" className="waypoint__subnav"></div>
            <h3 className="underline">Overview</h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div>
            <h5 className="underline">
              Reasons to Consider SRH U.S. Quality ETF (SRHQ)
            </h5>
            <ul>
              <li>
                <b>Focus on Quality Growth at a Reasonable Price</b> - SRHQ
                seeks to invest in companies that exhibit consistent and
                moderate revenue growth but do not trade at excessive
                valuations.
              </li>
              <li>
                <b>Diversified Portfolio of U.S. Stocks</b> - SRHQ seeks to
                provide access to a portfolio of U.S. stocks that exhibit a
                market beta close to that of widely followed broad-based U.S.
                benchmarks.
              </li>
              <li>
                <b>Disciplined Investment Process</b> - SRHQ’s underlying index
                utilizes an objective, rules-based methodology that is
                transparent.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div>
            <h5 className="underline">Investment Objective</h5>
            <p>
              The SRH U.S. Quality ETF (the “Fund”) seeks to provide investment
              results (before fees and expenses) that correspond to the SRH U.S.
              Quality Index (the “Index”).
            </p>
            <p>
              The Index is designed to track the performance of the stocks of
              domestic (U.S.) companies that have moderate and consistent
              revenue growth but do not trade at excessive valuations. Stocks
              are screened through value, growth, and quality metrics to
              determine eligibility in the Index. Indexes are unmanaged and one
              cannot invest directly in an index. Index performance returns do
              not reflect any management fees, transaction costs or expenses.
            </p>
          </div>
        </div>
        <div className="">
          <h5 className="underline">Fund Details</h5>
          <div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate">{`As of ${dailyData?.asOfDate ? moment(dailyData.asOfDate)?.format('MM/DD/YYYY') : ''}`}</span>
                </div>
                <table className="table--mod-6">
                  <tr>
                    <td>Ticker Symbol</td>
                    <td>SRHQ</td>
                  </tr>
                  <tr>
                    <td>Index Ticker</td>
                    <td>SRHUSQTR</td>
                  </tr>
                  <tr>
                    <td>CUSIP</td>
                    <td>210322103</td>
                  </tr>
                  <tr>
                    <td>Inception Date</td>
                    <td>10/04/2022</td>
                  </tr>
                  <tr>
                    <td>Expense Ratio</td>
                    <td>0.35%</td>
                  </tr>
                  <tr>
                    <td>Distribution Frequency</td>
                    <td>Quarterly</td>
                  </tr>
                  <tr>
                    <td>Exchange</td>
                    <td>NYSE Arca</td>
                  </tr>
                </table>
              </section>
            </div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate">{fundData?.asOfDate ? `As of ${moment(isValidDate(fundData?.asOfDate))?.format('MM/DD/YYYY')}` : ''}</span>
                </div>
                <table className="table--mod-6 portfolio-table">
                  <tr>
                    <td>30 Day SEC Yield</td>
                    <td>{fundData?.thirtyDaySecYield ? (fundData?.thirtyDaySecYield*100).toFixed(2)+"%" : "-"}</td>
                  </tr>
                  <tr>
                    <td>Distribution Yield</td>
                    <td>{fundData?.distributionYield ? (fundData?.distributionYield*100).toFixed(2)+"%" : "-"}</td>
                  </tr>
                </table>
              </section>
            </div>
            <div className="col span_1_of_3">
              <section>
                <div style={{ display: "flex" }}>
                  <span className="tilldate portfolio-asofdate">{`As of ${dailyData?.asOfDate ? moment(dailyData.asOfDate)?.format('MM/DD/YYYY') : ''}`}</span>
                </div>
                <table className="table--mod-6">
                  <tr>
                    <td>Total Net Assets</td>
                    <td id="assets">{dailyData?.totalNetAssets ? "$"+dailyData.totalNetAssets.toLocaleString() : "-"}</td>
                  </tr>
                  <tr>
                    <td>30 Day Median Bid/Ask Spread</td>
                    <td id="median">{dailyData?.thirtyDayMedianSpread ? dailyData.thirtyDayMedianSpread.toFixed(2)+"%" : "-"}</td>
                  </tr>
                  <tr>
                    <td>Number of Holdings</td>
                    <td id="totalHoldings">{fundData?.noOfHoldings ? fundData.noOfHoldings : props?.fullHoldingsData ? props.fullHoldingsData.length : "-"}</td>
                  </tr>
                  <tr>
                    <td>Premium / Discount</td>
                    <td id="premiumpercent">{(dailyData && dailyData?.premiumDiscount !== null) ? (dailyData.premiumDiscount*100).toFixed(2)+"%" : "-"}</td>
                  </tr>
                </table>
                <div>
                  <a
                    onClick={() => props.setChartShow(true)}
                    className="proceed-chart"
                  >
                    <img
                      src={Arrow}
                      height="15"
                      alt="logo"
                    />
                    <span className="view-chart">
                      View Premium/Discount Chart
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
