import Modal from "react-bootstrap/Modal";

function Definitions(props) {
  return (
    <Modal
      className="definitions"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Definitions</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>30 Day Median Bid/Ask Spread</b> is calculated by identifying the
          national best bid and national best offer ("NBBO") for each fund as of
          the end of each 10 second interval during each trading day of the last
          30 calendar days and dividing the difference between each such bid and
          offer by the midpoint of the NBBO. The median of those values is
          identified and that value is expressed as a percentage rounded to the
          nearest hundredth.
        </p>
        <p>
          <b>30 Day SEC Yield</b> is a standard yield calculation based on a
          formula developed by the Securities and Exchange Commission (SEC) for
          a fair comparison of a fund’s and represents displays a fund’s
          hypothetical annualized income as a percentage of its assets. The
          yield is calculated by dividing the net investment income per share
          earned during the 30-day period by the maximum offering price per
          share on the last day of the period. The yield figure reflects the
          dividends and interest earned during the 30-day period, after the
          deduction of fund expenses.
        </p>
        <p>
          <b>Beta</b> is a measure of a portfolio’s risk in relation to a
          broad-based benchmark. Beta represents a type of risk, systemic risk,
          that cannot be diversified away.
        </p>
        <p>
          <b>Distribution Yield</b> reflects the annual yield of the Fund if the
          most recent distribution amount paid by the Fund remained the same on
          a forward basis. Distribution Yield is calculated by annualizing the
          most recent distribution amount, based on the distribution frequency,
          and dividing by the Fund’s market price.
        </p>
        <p>
          <b>Premium/Discount</b> indicates whether a security is currently
          trading above (at a premium to) or below (at a discount to) its net
          asset value.
        </p>
        {/* <p>
          <b>Price to Book Ratio (P/B)</b> is a fundamental measure used to
          determine an investment’s valuation. It is calculated by taking the
          closing price divided by the latest fiscal year’s book value per
          share.
        </p>
        <p>
          <b>Price to Earnings Ratio (P/E)</b> is a fundamental measure used to
          determine an investment’s valuation. It is calculated by taking the
          closing price divided by the latest fiscal year’s earnings per share.
        </p> */}
      </Modal.Body>
    </Modal>
  );
}
export default Definitions;
