export const numberWithCommas = (value) => {
    if (value == 0) return 0;
    if (!value || isNaN(value)) return value;
    value = value % 1 === 0 ? value : toDecimal(value, 2);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const precisionRound = (number, precision) => {
    var factor = Math.pow(10, precision);
    var roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber.toFixed(precision);
};

export const toDecimal = (value, decimalLength = 1, forIndicativeNav = false) => {
    if (
        value === NaN ||
        value === "NaN" ||
        typeof value === "null" ||
        typeof value === "undefined" ||
        !value
    )
        return 0;
    const array = value.toString().split(".");
    let out;
    if (array.length === 2) {
        out = `${array[0]}.${array[1].substr(0, decimalLength)}`;
        if (forIndicativeNav) {
            out = Number(value).toFixed(decimalLength);
        } else {
            out = Number(
                `${array[0]}.${array[1]}`
            ).toFixed(decimalLength);
        }
    } else {
        out = Number(parseFloat(value).toFixed(decimalLength));
    }
    return !isNaN(out) ? out : 0;
};

export const isValidData = (value, type) => {
    if (value !== "" && !isNaN(value)) {
        if (type == "temp") {
            return parseFloat(precisionRound(value, 2)).toFixed(2) + "%";
        } else {
            return (
                "$" +
                numberWithCommas(precisionRound(value, 2))
                // parseFloat(
                //     parseFloat(precisionRound(value, 2)).toFixed(2)
                // ).toLocaleString("en")
            );
        }
    } else {
        return "-";
    }
}

export const isValidDate = (date) => {
    if (date?.split('T')?.length > 1) {
        return date?.split('T')[0]
    } else {
        return date
    }
}
